import axios from 'axios';
import to from 'await-to-js';
import { isEqual, find, merge, filter, get } from 'lodash';
import { ascending } from '@enums/sort-direction';
import { architectureGroupLevel } from '@enums/hierarchy';

const getInitialState = () => {
  return {
    scenarioMetadata: [], // scoped to current view e.g. pricing group
    allScenarioDescriptionsForWorkpackage: [], // required for jobs which may have been triggered from previous views.
    loading: false,
    deleting: false,
    aggregatedScenarios: [],
    calculating: [], // scenarioKeys of the scenarios being calculating after creation
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  getters: {
    failedEngineRuns: state => filter(state.scenarioMetadata, { lastEngineRunSuccessful: false }),
    toolStoreGroupDependency: (state, getters, rootState) => {
      if (!rootState.filters.scenario || !state.scenarioMetadata) {
        return null;
      }
      const scenarioKey = rootState.filters.scenario;
      const selectedScenario = find(state.scenarioMetadata, { scenarioKey });
      return get(selectedScenario, 'toolStoreGroupDependency', {});
    },
  },

  mutations: {
    setScenarioMetadata(state, scenarioMetadata) {
      state.scenarioMetadata = scenarioMetadata;
    },
    setAllScenarioDescriptionsForWorkpackage(state, allScenarioDescriptionsForWorkpackage) {
      state.allScenarioDescriptionsForWorkpackage = allScenarioDescriptionsForWorkpackage;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setCalculating(state, scenarioKey) {
      state.calculating.push(scenarioKey);
    },

    removeCalculating(state, scenarioKey) {
      state.calculating = state.calculating.filter(key => key !== scenarioKey);
    },

    setDeletingStatus(state, isDeleting) {
      state.deleting = isDeleting;
    },

    resetState(state) {
      Object.assign(state, getInitialState());
    },

    setAggregatedScenarios(state, aggregatedScenarios) {
      state.aggregatedScenarios = aggregatedScenarios;
    },
  },

  actions: {
    async fetchScenarioMetadata(
      { rootState, commit, dispatch, state },
      { params = {}, unsetFilter = true } = {}
    ) {
      commit('setScenarioMetadata', []);
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      params = {
        sortBy: 'scenarioDescription',
        sortDirection: ascending,
        ...params,
      };

      if (rootState.filters.pricingGroup) {
        params = merge(params, {
          where: { hierarchyId: rootState.filters.pricingGroup },
        });
      }

      const [err, { data: scenarioMetadata }] = await to(
        axios.get(`/api/scenario-metadata/workpackage/${workpackageId}`, { params })
      );
      if (err) throw new Error(err.message);
      if (!isEqual(state.scenarioMetadata, scenarioMetadata)) {
        commit('setScenarioMetadata', scenarioMetadata);
        // Clear selected scenario when new scenario-metadata fetched
        if (unsetFilter) {
          dispatch('filters/resetFilter', { filterName: 'scenario' }, { root: true });
        }
      }
      commit('setLoading', false);
    },

    async fetchAllScenarioDescriptionsForWorkpackage({ rootState, commit, state }) {
      // no loading state because this is only used for notifications.
      commit('setAllScenarioDescriptionsForWorkpackage', []);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, { data: allScenarioDescriptions }] = await to(
        axios.get(`/api/scenario-metadata/workpackage/${workpackageId}`, {
          params: { pick: ['scenarioKey', 'scenarioDescription'] },
        })
      );
      if (err) throw new Error(err.message);
      if (!isEqual(state.allScenarioDescriptions, allScenarioDescriptions)) {
        commit('setAllScenarioDescriptionsForWorkpackage', allScenarioDescriptions);
      }
    },

    async deleteScenario({ commit }, scenario) {
      commit('setDeletingStatus', true);
      const [err] = await to(
        axios.delete(
          `/api/scenario-metadata/${scenario.scenarioKey}/workpackage/${scenario.workpackageId}`,
          { data: { scenario } }
        )
      );
      // setDeletingStatus in afterDeleteScenario if no error was thrown, so that duplicate delete jobs aren't triggered.
      if (err) commit('setDeletingStatus', false);

      return err === null;
    },

    async afterDeleteScenario({ commit, dispatch }) {
      commit('setDeletingStatus', false);
      dispatch('fetchScenarioMetadata');
    },

    async createScenarioMetadata({ dispatch }, scenarioMetadata) {
      const [err, { data: result }] = await to(
        axios.post('/api/scenario-metadata', scenarioMetadata)
      );
      if (err) throw new Error(err.message);
      dispatch('fetchScenarioMetadata');
      return result;
    },

    async updateScenarioMetadata({ dispatch, commit }, { updates = {}, id } = {}) {
      commit('setLoading', true);
      const [err] = await to(axios.patch(`/api/scenario-metadata/${id}`, updates));
      if (err) throw new Error(err.message);
      dispatch('fetchScenarioMetadata', { unsetFilter: false });
    },

    async electNewCandidateScenario(
      { rootState },
      { newCandidateScenarioKey, oldCandidateScenarioKey, pricingGroupId } = {}
    ) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      // update both scenario-metadata and aggregated-scenario-results collections here
      await axios.patch(
        `/api/scenario-metadata/${newCandidateScenarioKey}/workpackage/${workpackageId}/elect-candidate`,
        { pricingGroupId, oldCandidateScenarioKey }
      );
    },

    async getAggregatedScenarios({ commit, dispatch, rootState }) {
      try {
        const isUnitManagerView = rootState.route.name === 'pricingImpactView';
        if (isUnitManagerView) return;
      } catch (e) {
        console.error(e);
      }
      const params = {
        aggregateScenarios: true,
        aggregationLevel: architectureGroupLevel,
        pricingGroupId: rootState.filters.pricingGroup,
      };
      const [err, results] = await to(
        dispatch('gridView/fetchAggregatedHierarchyLevelItems', params, { root: true })
      );
      if (err || !results || get(results, 'preventUpdate')) {
        return;
      }
      commit('setAggregatedScenarios', results.items);
    },

    async addNewScenario({ rootState, commit }, { copiedScenarioKey, scenarioName }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, { data: result }] = await to(
        axios.post(`/api/scenario-metadata/${copiedScenarioKey}/workpackage/${workpackageId}`, {
          scenarioName,
        })
      );
      if (err) throw new Error(err.message);
      if (result) {
        // after creating a scenario, we run engine for it, so the response here is the result of runPricingEngineDag
        // which contains Argo job metadata. Extracting scenarioKey from the parameters.
        try {
          // Extract the JSON string from the parameters array
          const jsonString = get(result, 'data.spec.arguments.parameters[0].value');
          const parsedJson = JSON.parse(jsonString);
          const scenarioKey = parsedJson.job_params.scenario_key;
          commit('setCalculating', scenarioKey);
          return scenarioKey;
        } catch (parseError) {
          console.error('Failed to parse JSON:', parseError);
        }
      }
    },

    async afterCreateScenario({ dispatch, rootState }) {
      await Promise.all([
        dispatch('fetchScenarioMetadata', {
          params: { where: { hierarchyId: rootState.filters.pricingGroup } },
        }),
        dispatch('fetchAllScenarioDescriptionsForWorkpackage'),
      ]);
      await dispatch('getAggregatedScenarios');
    },

    async getSelectedScenario({ rootState, state }) {
      const scenarioKey = rootState.filters.scenario;
      return find(state.scenarioMetadata, { scenarioKey });
    },

    resetState({ commit }) {
      commit('resetState');
    },
  },
};

export default store;
