import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    [
      !_vm.hidePricingGroupSelection
        ? _c("pricing-filter", {
            attrs: {
              filter: _vm.pricingGroupFilter,
              loading: _vm.hierarchyLoading,
              dense: _vm.dense,
              solo: _vm.solo,
              "hide-label": _vm.hideLabel,
            },
            on: { filterSelected: _vm.selectPricingGroup },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("pricing-filter", {
        attrs: {
          filter: _vm.scenarioFilters,
          loading: _vm.scenarioLoading,
          "hide-label": _vm.hidePricingGroupSelection,
          dense: _vm.dense,
          disabled: _vm.scenarioCalculating,
          solo: _vm.solo,
        },
        on: { filterSelected: _vm.selectScenario },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }