'use strict';

// baseTranslation in translations json file should contain starting/finished/failed keys
// actions can include starting/finished/failed keys - the action names should include the module
// payloadKey is the payload used in the notification
module.exports = {
  functions: {
    run_pricing: {
      baseTranslation: 'notifications.run_pricing',
      actions: {
        finished: 'gridView/updateScenarioResults',
        failed: 'gridView/updateScenarioResults',
      },
      payloadKey: 'scenario_key',
    },
    run_pricing_for_all: {
      baseTranslation: 'notifications.run_pricing_for_all',
      payloadKey: 'workpackage_id',
      actions: {
        finished: 'gridView/postRunEngineForAllScenarios',
        failed: 'gridView/postRunEngineForAllScenarios',
        // handle case where engine is started by argo. e.g. transfer workpackage.
        // doesn't start from runEngineForAllScenarios so setRecalculatingAll needs to be called from checkRecalculatingAll
        starting: 'gridView/checkRecalculatingAll',
      },
    },
    run_pricing_for_pgs: {
      baseTranslation: 'notifications.run_pricing_for_pgs',
      payloadKey: 'workpackage_id_level_entry_key',
      actions: {
        finished: 'gridView/postRunEngineForPricingGroups',
        failed: 'gridView/postRunEngineForPricingGroups',
      },
    },
    run_pricing_for_specific_pgs: {
      baseTranslation: 'notifications.run_pricing_for_specific_pgs',
      payloadKey: 'workpackage_id_level_entry_key',
      actions: {
        finished: 'gridView/postRunEngineForSpecificPricingGroups',
        failed: 'gridView/postRunEngineForSpecificPricingGroups',
      },
    },
    run_create_workpackage: {
      baseTranslation: 'notifications.run_create_workpackage',
      payloadKey: 'description', // name of the new WP
      actions: {
        finished: 'workpackages/afterCreateWorkpackage',
        failed: 'workpackages/afterFailedCreateWorkpackage',
      },
    },
    create_scenarios: {
      baseTranslation: 'notifications.create_scenarios',
      actions: {
        finished: 'scenarioMetadata/afterCreateScenario',
        failed: 'scenarioMetadata/afterCreateScenario',
      },
      payloadKey: 'scenario_description',
    },
    run_delete_workpackage: {
      baseTranslation: 'notifications.run_delete_workpackage',
      payloadKey: 'description', // name of the deleted WP
      actions: {
        finished: 'workpackages/afterDeleteWorkpackage',
        failed: 'workpackages/afterDeleteWorkpackage',
      },
    },
    delete_scenario: {
      baseTranslation: 'notifications.delete_scenario',
      actions: {
        finished: 'scenarioMetadata/afterDeleteScenario',
        failed: 'scenarioMetadata/afterDeleteScenario',
      },
      payloadKey: 'description', // name of the deleted scenario
    },
    update_hierarchy: {
      baseTranslation: 'notifications.update_hierarchy',
      actions: {
        finished: 'attributes/postHierarchyUpload',
      },
    },
    // TODO: handle other failure states.
    // if finished hook sets loading to false, failure should also set to false to avoid infinite spinners.
    update_attributes: {
      baseTranslation: 'notifications.update_attributes',
      actions: {
        finished: 'attributes/postAttributeUpload',
      },
    },
    update_competitors: {
      baseTranslation: 'notifications.update_competitors',
      actions: {
        finished: 'attributes/postCompetitorUpload',
      },
    },
    update_engine_inputs: {
      baseTranslation: 'notifications.update_engine_inputs',
      actions: {
        finished: 'attributes/postEngineInputsUpload',
      },
    },
    export_penalty_data: {
      baseTranslation: 'notifications.export_penalty_data',
    },
    update_price_overrides: {
      // TODO PRICE-4271: Used by PO editor V1, to be removed
      baseTranslation: 'notifications.update_price_overrides',
      actions: {
        finished: 'scenarioProducts/postPriceOverridesUpload',
      },
    },
    transfer_workpackage: {
      baseTranslation: 'notifications.transfer_workpackage',
      actions: {
        finished: 'workpackages/afterTransferWorkpackage',
        failed: 'workpackages/afterTransferWorkpackage',
      },
      payloadKey: 'destination_workpackage_id', // id of the WP
    },
    export_workpackage: {
      baseTranslation: 'notifications.export_workpackage',
      payloadKey: 'description', // name of the WP
    },
    run_wholesale_engine: {
      baseTranslation: 'notifications.run_wholesale_engine',
      payloadKey: 'workpackage_id', // id of the WP
      actions: {
        finished: 'wholesale/reloadWholesaleDataAfterEngineRun',
        failed: 'wholesale/reloadWholesaleDataAfterEngineRun',
      },
    },
    run_wholesale_export: {
      baseTranslation: 'notifications.run_wholesale_export',
      payloadKey: 'workpackage_id', // id of the WP
      actions: {
        finished: 'wholesale/fetchWholesaleExports',
        failed: 'wholesale/handleWholesaleExportFailed',
        running: 'wholesale/fetchWholesaleExports',
      },
    },
    create_wholesale_grid: {
      baseTranslation: 'notifications.create_wholesale_grid',
      payloadKey: 'gridDescription',
    },
    upload_wholesale_grid: {
      baseTranslation: 'notifications.upload_wholesale_grid',
    },
    export_wholesale_results: {
      baseTranslation: 'notifications.export_wholesale_results',
      payloadKey: 'description', // name of the WP
    },
    export_line_pricing: {
      baseTranslation: 'notifications.export_line_pricing',
      payloadKey: 'description', // name of the WP
    },
    recalculate_kpis: {
      baseTranslation: 'notifications.recalculate_kpis',
      payloadKey: 'product_key',
    },
  },
};
