<template>
  <div class="audit">
    <v-container>
      <v-row>
        <v-col>
          <h2>{{ $t('audit.auditLogsExportHeader') }}</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <audit-date-picker v-model="auditLogExportStartDate" :label="$t('audit.startDate')" />
        </v-col>
        <v-col cols="2">
          <audit-date-picker v-model="auditLogExportEndDate" :label="$t('audit.endDate')" />
        </v-col>
        <v-col cols="4">
          <v-btn :loading="loading" @click="downloadAuditReport">
            {{ $t('actions.downloadAudit') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h2>{{ $t('audit.analysisExportHeader') }}</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <audit-date-picker
            v-model="productAnalysisExportStartDate"
            :label="$t('audit.startDate')"
          />
        </v-col>
        <v-col cols="2">
          <audit-date-picker v-model="productAnalysisExportEndDate" :label="$t('audit.endDate')" />
        </v-col>
        <v-col cols="2">
          <v-checkbox v-model="includeAllStoreGroups" :label="$t('audit.includeAllStoreGroups')" />
        </v-col>
        <v-col cols="4">
          <v-btn :loading="loadingAnalysisDownload" @click="downloadProductDateRange">
            {{ $t('actions.downloadAudit') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import to from 'await-to-js';
import moment from 'moment';
import downloadXlsxFile from '../../store/utils/download-xlsx-file';
import downloadCsvUtils from '../../store/utils/download-csv';
import { get, keys } from 'lodash';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      loadingAnalysisDownload: false,
      auditLogExportStartDate: null,
      auditLogExportEndDate: null,
      productAnalysisExportStartDate: null,
      productAnalysisExportEndDate: null,
    };
  },

  computed: {
    ...mapState('clientConfig', ['i18nconfig']),
  },

  created() {
    const todaysDate = moment().format('YYYY-MM-DD');
    const threeMonthsAgo = moment()
      .subtract(3, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');

    this.auditLogExportStartDate = threeMonthsAgo;
    this.productAnalysisExportStartDate = threeMonthsAgo;

    this.auditLogExportEndDate = todaysDate;
    this.productAnalysisExportEndDate = todaysDate;
  },

  methods: {
    async downloadAuditReport() {
      this.loading = true;
      const params = {
        startDate: this.auditLogExportStartDate,
        endDate: this.auditLogExportEndDate,
      };

      const [err, response] = await to(axios.get(`/api/audit-log/report`, { params }));
      if (err) {
        this.loading = false;
        throw new Error(err.message);
      }

      const fileName = `${this.$t('actions.downloadAudit')}.xlsx`;

      const dataPath = `data`;
      const dataArray = get(response, dataPath, []);
      const headers = keys(get(dataArray, 0));
      downloadXlsxFile({ headers: [headers], rows: response.data }, fileName);
      this.loading = false;
    },

    async downloadProductDateRange() {
      this.loadingAnalysisDownload = true;
      const params = {
        startDate: this.productAnalysisExportStartDate,
        endDate: this.productAnalysisExportEndDate,
        includeAllStoreGroups: this.includeAllStoreGroups,
      };
      const [err, response] = await to(
        axios.get(`/api/audit-log/download-product-date-range`, {
          params,
        })
      );
      if (err) {
        this.loadingAnalysisDownload = false;
        throw new Error(err.message);
      }
      this.loadingAnalysisDownload = false;

      downloadCsvUtils.downloadCsvFile(response.data, 'Analysis_Export.csv');
    },
  },
};
</script>

<style scoped lang="scss">
.audit {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  background-color: white;
  font-size: 1.4rem;

  &__container {
    padding: 2rem;
  }
}
</style>
