import { find, get } from 'lodash';
import { functions } from '@enums/asynchronous-functions';
import { jobStatuses } from '@enums/jobapi';

const store = {
  namespaced: true,

  state: {
    progressNotifications: [],
    notifications: [],
    notificationLimit: 1,
    eventStream: null,
  },

  getters: {
    notifications: state => state.notifications.slice(-state.notificationLimit),
    progressNotifications: state => state.progressNotifications.slice(-state.notificationLimit),
  },

  mutations: {
    addNotification(state, notification) {
      state.notifications.push(notification);
    },

    addProgressNotification(state, progressNotification) {
      state.progressNotifications.push(progressNotification);
    },

    setEventStream(state, { eventStream }) {
      state.eventStream = eventStream;
    },

    resetNotifications(state) {
      state.notifications = [];
    },

    resetProgressNotifications(state) {
      state.progressNotifications = [];
    },
  },

  actions: {
    resetAllNotifications({ commit }) {
      commit('resetNotifications');
      commit('resetProgressNotifications');
    },
    openNotificationStream({ rootState, commit, dispatch, state }) {
      let reconnectFrequencySeconds = 1; // first attempt to reconnect after 1 second
      let reconnectCount = 0;
      let source;

      // reconnection handling from https://stackoverflow.com/a/54385402
      function throttle(func) {
        let timeout;
        return () => {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            timeout = null;
            func();
          }, reconnectFrequencySeconds * 1000);
        };
      }

      // attempt reconnection every `reconnectFrequencySeconds`
      const reconnectFunc = throttle(() => {
        reconnectCount += 1;

        // Force a token refresh, as this is the main reason why eventstream connections drops
        // Only force resfresh every 5th connection attempt, to avoid refreshing the token just after another refresh
        if (reconnectCount > 1 && reconnectCount % 5 === 0) {
          dispatch('context/refreshUserContext', {}, { root: true });
        }

        // eslint-disable-next-line no-use-before-define
        setupEventSource();

        // Double every attempt to avoid overwhelming server
        reconnectFrequencySeconds = Math.min(reconnectFrequencySeconds * 2, 15);
      });

      function setupEventSource() {
        if (state.eventStream) {
          state.eventStream.close();
          commit('setEventStream', { eventStream: null });
        }
        source = new EventSource('/api/notifications');

        source.addEventListener('open', () => {
          reconnectCount = 0;
        });

        source.addEventListener('error', function() {
          // close the source (e.g. on token timeout)
          source.close();
          reconnectFunc();
        });

        source.addEventListener('message', function(e) {
          const { lastEventId, data: message } = e;

          // blank messages are sent intermittently to keep the EventSource open
          if (message === '') {
            return;
          }
          const [jobId, jobType] = lastEventId.split(':');
          const [payload, jobStatus] = message.split(':');

          let notificationPayload = payload; // if the payload isn't a document key, use it as is
          let id; // may need the id in after hooks. e.g. update_workpackage.

          // mappedJobType should be run_pricing_for_all if running run_pricing_for_all_parallel
          let mappedJobType = jobType;
          if (jobType.startsWith('run_pricing_for_all')) {
            mappedJobType = 'run_pricing_for_all';
          }
          // if payload is a database key, add a check here
          if (get(functions, [mappedJobType, 'payloadKey']) === 'scenario_key') {
            const {
              allScenarioDescriptionsForWorkpackage: scenarioMetadataArray,
            } = rootState.scenarioMetadata;
            const scenarioMetadata = find(scenarioMetadataArray, { scenarioKey: payload });
            notificationPayload = get(scenarioMetadata, 'scenarioDescription');
          }

          if (get(functions, [mappedJobType, 'payloadKey']) === 'workpackage_id') {
            const workpackage = find(rootState.workpackages.workpackages, { _id: payload });
            notificationPayload = workpackage
              ? workpackage.description
              : rootState.workpackages.selectedWorkpackage.description;
            id = payload;
          }

          if (get(functions, [mappedJobType, 'payloadKey']) === 'destination_workpackage_id') {
            const workpackage = find(rootState.workpackages.workpackages, { _id: payload });
            notificationPayload = workpackage
              ? workpackage.description
              : rootState.workpackages.selectedWorkpackage.description;
            id = payload;
          }

          if (get(functions, [mappedJobType, 'payloadKey']) === 'workpackage_id_level_entry_key') {
            const workpackage = find(rootState.workpackages.workpackages, { _id: payload });
            notificationPayload = workpackage
              ? workpackage.description
              : rootState.workpackages.selectedWorkpackage.description;
            id = payload;
          }

          if (
            jobStatus === jobStatuses.sentToJobApiFromCreation &&
            mappedJobType.startsWith('run_pricing_for_all')
          ) {
            notificationPayload = payload;
          }

          if (jobStatus === 'progress') {
            // frontend relies on jobType key to display progress notifications
            const progressNotification = { jobId, jobType: mappedJobType, jobStatus, payload };
            return commit('addProgressNotification', progressNotification);
          }

          const { baseTranslation } = get(functions, mappedJobType, {});
          // create a notification if a translation exists for the job type
          if (baseTranslation) {
            const notification = {
              id: `${jobId}:${jobStatus}`,
              jobStatus,
              notificationPayload,
              baseTranslation,
            };
            commit('addNotification', notification);
          }
          // if an action is defined for the function/jobStatus combination, dispatch it
          const action = get(functions, [mappedJobType, 'actions', jobStatus]);
          if (action) {
            dispatch(action, { jobStatus, id, payload }, { root: true });
          }
        });
        commit('setEventStream', { eventStream: source });
      }
      // initial setup
      setupEventSource();
    },
  },
};

export default store;
