import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { "align-center": "", "fill-height": "" } },
    [
      !_vm.hideLabel
        ? _c("span", { staticClass: "page-rule-filter" }, [
            _vm._v(_vm._s(_vm.filter.name) + ":"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(VAutocomplete, {
        staticClass: "px-3 ma-0 pt-0 mb-1",
        attrs: {
          dense: _vm.dense,
          solo: _vm.solo,
          disabled: _vm.disabled,
          "hide-details": "",
          loading: _vm.loading,
          items: _vm.filter.values,
          "item-text": _vm.filter.label,
          "item-value": _vm.getItemValue(),
          label: _vm.hideLabel
            ? ""
            : _vm.$t("filters.selectPrompt", { filterName: _vm.filter.name }),
          value: _vm.overrideValue || _vm.getSelectedFilter(_vm.filter.state),
          height: "30",
        },
        on: {
          input: function ($event) {
            return _vm.setSelection({
              filterValue: $event,
              filterName: _vm.filter.state,
            })
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (props) {
              return [
                _c("span", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(props.item[_vm.filter.label])),
                ]),
              ]
            },
          },
          {
            key: "no-data",
            fn: function () {
              return [
                !_vm.loading
                  ? _c(
                      "div",
                      [
                        _c(
                          VAlert,
                          {
                            attrs: {
                              value: true,
                              color: "error",
                              icon: "warning",
                              outlined: "",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("filters.noData")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          VAlert,
                          {
                            attrs: {
                              value: true,
                              color: "info",
                              icon: "info",
                              outlined: "",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("general.loadingMessage")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }