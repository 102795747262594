'use strict';

// PRICE-3670 cleanup.
// note some of these are used to check status from redis, not directly from jobapi /argo
// see status_updaters.py
const jobStatuses = {
  starting: 'starting',
  finished: 'finished',
  failed: 'failed',
  timeout: 'timeout',
  alreadyRunning: 'already_running',
  alreadyExists: 'already_exists',
  sentToJobApi: 'sent_to_job_api',
  sentToJobApiFromCreation: 'sent_to_job_api_from_creation',
  finishedCreationRecalculate: 'finished_creation_recalculate',
  noResults: 'no_results', // job finished with empty results
};
const argoJobStatusMappings = {
  Succeeded: jobStatuses.finished,
  Failed: jobStatuses.failed,
  Error: jobStatuses.failed,
};
module.exports = {
  jobStatuses,
  argoJobStatusMappings,
};
