<template>
  <header>
    <!-- Standard navbar otherwise -->
    <v-app-bar
      app
      class="pricing-toolbar elevation-6"
      :class="{ warning: notUsingLocalDatabase, ...configurableNavbarColor }"
    >
      <v-toolbar-items>
        <v-btn exact text to="/" class="subheading font-weight-bold home-link" depressed>
          <!-- make the (optional) client logo invisible until loaded -->
          <v-img
            id="client-logo"
            src="/organisation-assets/logo.png"
            style="visibility: invisible"
            @load="logoLoaded"
          />
          <v-img :src="pricingLogo" width="12rem" />
        </v-btn>
      </v-toolbar-items>
      <v-spacer />
      <v-toolbar-items v-for="(route, index) in routes" :key="index">
        <template v-if="route.meta && route.meta.featureFlag">
          <feature-toggle :toggle="route.meta.featureFlag">
            <main-navbar-tab
              :route="route"
              :is-work-package-selected="isWorkPackageSelected"
              :is-navbar-highlighted="isNavbarHighlighted"
              :index="index"
            />
          </feature-toggle>
        </template>
        <main-navbar-tab
          v-else
          :route="route"
          :is-work-package-selected="isWorkPackageSelected"
          :is-navbar-highlighted="isNavbarHighlighted"
          :index="index"
        />
      </v-toolbar-items>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          v-if="isAdmin"
          id="navbar-settings"
          text
          exact
          class="text-none subheading settings-link"
          to="/admin"
          depressed
        >
          <span><v-icon v-once color="primary">mdi-settings</v-icon></span>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text class="user-menu" v-on="on" @click="isExpanded = !isExpanded">
              <v-avatar class="avatar" height="4rem" width="4rem" color="primary" size="36">
                <span class="white--text headline">{{ profileNameAndInitials.initials }}</span>
              </v-avatar>
              <span class="name-text link" :class="{ 'link-dark': isNavbarHighlighted }">{{
                profileNameAndInitials.fullName
              }}</span>
              <main-expand-button :is-expanded="isExpanded" :background="isNavbarHighlighted" />
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  id="navbar-logout"
                  text
                  class="text-none subheading nav-link"
                  depressed
                  @click="onLogout()"
                >
                  <span class="align-bottom">{{ startCase($t('actions.logout')) }}</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <div
      v-if="!includes(['home', 'admin'], $route.name) && isWorkPackageSelected"
      class="workpackage-selection"
    >
      <v-layout row>
        <v-flex xs3 description>
          <a @click="goToWorkpackages">
            <v-icon v-once color="#2f477c" class="font-weight-bold"> chevron_left </v-icon>
            <span v-if="dateHasPassed(selectedWorkpackage.goLiveDate)">
              ({{ $t('workPackages.archived') }})
            </span>
            {{ selectedWorkpackage.description | truncate(workpackageTruncationLength) }}
          </a>
        </v-flex>
        <v-flex xs2>
          {{ selectedWorkpackage.storeFormat }}
        </v-flex>
        <v-flex xs2 class="text-xs-right" dates>
          {{ $t('workPackages.completionDate') }}:
          <span class="font-weight-bold">
            {{ completionDate }}
          </span>
          / {{ $t('workPackages.goLiveDate') }}:
          <span class="font-weight-bold">
            {{ formatLocalisedDate(selectedWorkpackage.goLiveDate) }}
          </span>
        </v-flex>

        <v-flex xs2>
          <div class="actions mr-2">
            <v-switch
              v-if="toggleLogic.saveResultsToDatabricks && isOWUser"
              v-model="readFromDatabricks"
              :label="$t('gridView.readFromDatabricks')"
              class="mr-4 pt-0"
              primary
              dense
              hide-details
              @change="toggleReadFromDatabricks"
            />
            <recalculate-all-button />
          </div>
        </v-flex>

        <v-flex xs3>
          <alerts v-if="displayAlerts()" />
        </v-flex>
      </v-layout>
    </div>
  </header>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { includes, isEmpty, startCase } from 'lodash';
import dateHasPassed from '../store/utils/date-checking';
import { wholesale as wholesalePageIndex } from '@enums/page-index';
import workpackageTypes from '@enums/workpackage-types';
import { navbarColorHighlightAll, navbarColorHighlightOw } from '@enums/feature-flags';

const pricingLogo = require('../../img/logo-rtls-pricing.png');

export default {
  data() {
    return {
      sidebar: false,
      isExpanded: false,
      workpackageTruncationLength: 53,
      dateHasPassed,
      pricingLogo,
      readFromDatabricks: true,
    };
  },
  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('clientConfig', ['notUsingLocalDatabase', 'toggleLogic']),
    ...mapState('gridView', ['activeTableFilters', 'calculatingScenarioResults']),
    ...mapGetters('context', [
      'isAdmin',
      'isWholesaleManager',
      'isWholesaleAnalyst',
      'isOWUser',
      'profileNameAndInitials',
    ]),
    ...mapState('filters', ['selectedLevelEntryKey']),

    routes() {
      return this.$router.options.routes
        .filter(route => {
          if (route.index === wholesalePageIndex) {
            if (!this.canAccessWholesale) return false;
            if (this.selectedWorkpackage.type !== workpackageTypes.master) return false;
          }
          return !route.omitFromNav;
        })
        .sort((a, b) => {
          if (Number.isFinite(a.index) && Number.isFinite(b.index)) return a.index - b.index;
          if (Number.isFinite(a.index)) return -1;
          if (Number.isFinite(b.index)) return 1;

          return a.name > b.name ? -1 : 0;
        });
    },

    canAccessWholesale() {
      return this.isWholesaleManager || this.isWholesaleAnalyst;
    },

    isWorkPackageSelected() {
      return !isEmpty(this.selectedWorkpackage);
    },

    completionDate() {
      return this.selectedWorkpackage.completionDate
        ? this.formatLocalisedDate(this.selectedWorkpackage.completionDate)
        : '-';
    },

    isNavbarHighlighted() {
      return (
        this.isOWUser &&
        !isEmpty(
          this.toggleLogic[navbarColorHighlightOw] || this.toggleLogic[navbarColorHighlightAll]
        )
      );
    },

    configurableNavbarColor() {
      return this.isOWUser && this.toggleLogic[navbarColorHighlightOw]
        ? { [this.toggleLogic[navbarColorHighlightOw]]: true }
        : { [this.toggleLogic[navbarColorHighlightAll]]: true };
    },

    isUnitManagerView() {
      return this.$route.path.startsWith('/pricing/impact-view');
    },
  },
  methods: {
    startCase,
    ...mapActions('context', ['logout', 'resetState']),
    ...mapActions('gridView', [
      'fetchAggregations',
      'getAlertCounts',
      'fetchItems',
      'removeActiveTableFilter',
    ]),
    ...mapActions('scenarioMetadata', ['getAggregatedScenarios']),
    ...mapMutations('gridView', ['setReadFromDatabricks']),

    onLogout() {
      return this.logout().then(
        redirectUrl => {
          this.$router.push(redirectUrl);
          this.resetState();
        },
        () => {
          // eslint-disable-next-line no-console
          console.log('Logout error!');
        }
      );
    },

    goToWorkpackages() {
      this.$router.push({ path: '/home' });
    },

    displayAlerts() {
      // display alerts on every page except wholesale
      return !this.$route.path.includes('/wholesale');
    },

    includes,

    logoLoaded() {
      // if the client logo exists, make it visible and style it
      document.getElementById('client-logo').style.visibility = 'visible';
      document.getElementById('client-logo').style.width = '2.5rem';
      document.getElementById('client-logo').style['margin-right'] = '1rem';
    },

    toggleReadFromDatabricks() {
      this.setReadFromDatabricks(this.readFromDatabricks);
      this.fetchAggregations();
      if (!this.isUnitManagerView) {
        this.getAggregatedScenarios();
      }
      this.getAlertCounts();
      if (this.selectedLevelEntryKey) {
        this.removeActiveTableFilter({ filter: this.activeTableFilters, skipFetch: true });
        this.fetchItems({
          levelEntryKey: this.selectedLevelEntryKey,
          isUnitManagerView: this.isUnitManagerView,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/mixins/_nav-tab.scss';
@import '@style/components/_hierarchy-item-shared.scss';

.actions {
  @include hierarchy-item-actions-panel();
  .v-btn {
    height: 2.4rem;
    font-size: 1.25rem;
  }
}

.pricing-toolbar {
  background-color: $pricing-white;
  z-index: 100;
}

.home-link {
  color: $pricing-light-blue;
}
.home-link:hover {
  text-decoration: none;
}

.link-dark {
  color: $pricing-white;
}

.nav-link {
  @include nav-link-overrides();

  &.nav-link-dark {
    color: $pricing-white;
  }
}

.active-link {
  @include active-link-overrides();
}

.v-btn {
  @include v-btn-overrides();
}

.settings-link {
  color: $pricing-primary;
}

.align-bottom {
  margin-bottom: -1rem;
}

.pricing-toolbar.v-app-bar--fixed.warning {
  background-color: $pricing-orange-dark !important;
}

.workpackage-selection {
  position: fixed;
  background-color: white;
  width: 100%;
  padding: 3rem 3rem 1rem;
  z-index: 90;
  color: #4a4a4a;
  font-size: 1.4rem;

  .description {
    font-weight: 600;
  }

  .dates {
    color: #37424a;
    font-size: 1.2rem;
  }
}

.avatar {
  margin-right: 1rem;
}

#navbar-logout {
  padding-bottom: 1rem;
  margin-left: 3rem;
}
</style>
