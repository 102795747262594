<template>
  <v-card :key="productKey" flat>
    <v-layout v-if="expandedProduct" wrap border-bottom>
      <v-flex xs9 border-bottom>
        <v-layout wrap>
          <v-flex xs12>
            <price-history-chart
              :price-history="addChartCoordinates(expandedProduct.priceHistoryWeek)"
              :scenario-price="
                expandedProduct.scenarioResults ? expandedProduct.scenarioResults.scenarioPrice : {}
              "
              :scenario-cost="expandedProduct.scenarioCost"
              :cost-history="addChartCoordinates(expandedProduct.costHistoryWeek)"
              :competitors="expandedProduct.competitor"
              :competitor-names-map="competitorDescriptionToDisplayDescriptionMap"
            />
          </v-flex>
        </v-layout>
        <v-layout border-top>
          <v-flex xs6>
            <penalty-functions
              :scenario-results="expandedProduct.scenarioResults"
              :product-key="productKey"
              :scenario-key="scenarioKey"
              :tool-store-group-key="toolStoreGroupKey"
              :competitor-names-map="competitorDescriptionToDisplayDescriptionMap"
            />
          </v-flex>
          <v-flex xs6 border-left pa-2>
            <h3>{{ $t('gridView.settings') }}</h3>
            <p class="my-0">{{ $t('gridView.linksToSettings') }}:</p>
            <v-container pb-0 pt-2>
              <v-row class="goto-row-separator">
                <h4>{{ $tc(`hierarchy.hierarchyLevels.${architectureGroupLevel}`, 1) }}</h4>
              </v-row>
              <v-row>
                <v-col cols="8" class="py-1 px-0 d-flex align-center">
                  {{ architectureGroup.levelEntryDescription }}
                  <v-checkbox
                    v-model="isFilteringByArchitectureGroupEnabled"
                    prepend-icon="filter_list"
                    class="checkbox ml-2 pa-0"
                    hide-details
                    dense
                    :disabled="loading"
                    @click.prevent.stop="onToggleFilteringByArchitectureGroup"
                  />
                </v-col>
                <v-col cols="4" class="py-1 px-0 text-right">
                  <v-btn small class="ok-btn" @click="goToArchitectureGroup">
                    {{ $t('gridView.goToButtonText') }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row class="goto-row-separator">
                <h4>{{ $t(`gridView.architectureDrivers`) }}</h4>
              </v-row>
              <v-row>
                <v-col cols="8" class="py-1 px-0">
                  {{ /* Finding list relevant ADs is complicated. Compromise for now is to show AG name instead. */ }}
                  {{ architectureGroup.levelEntryDescription }}
                </v-col>
                <v-col cols="4" class="py-1 px-0 text-right">
                  <v-btn small class="ok-btn" @click="goToArchitectureDrivers">
                    {{ $t('gridView.goToButtonText') }}
                  </v-btn>
                </v-col>
              </v-row>

              <feature-toggle
                v-if="!isUnitManagerView"
                :toggle="displayArchitectureSubGroupSplittingAttributes"
              >
                <v-row class="goto-row-separator">
                  <h4>{{ $t(`gridView.architectureSubGroupSplittingAttributes`) }}</h4>
                </v-row>
                <v-row>
                  <v-col cols="8" class="py-1 px-0">
                    {{ formattedArchitectureSubGroupDescription }}
                  </v-col>
                  <v-col cols="4" class="py-1 px-0 text-right">
                    <v-btn
                      small
                      class="ok-btn"
                      @click="goToArchitectureSubGroupSplittingAttributes"
                    >
                      {{ $t('gridView.viewButtonText') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </feature-toggle>

              <v-row class="goto-row-separator">
                <h4>{{ $t(`gridView.linePricingGroup`) }}</h4>
              </v-row>
              <v-row>
                <v-col cols="8" class="py-1 px-0">
                  {{ linePricingGroup.linePricedGroupDescription }}
                </v-col>
                <v-col cols="4" class="py-1 px-0 text-right">
                  <v-btn small class="ok-btn" @click="goToLinePricingGroups">
                    {{ $t('gridView.goToButtonText') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs3 border-left border-bottom>
        <product-attributes :item="expandedProduct" />
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { isEmpty } from 'lodash';
import { architectureGroupLevel } from '@enums/hierarchy';
import { displayArchitectureSubGroupSplittingAttributes } from '@enums/feature-flags';
import hierarchyFiltersMixin from '../../../mixins/hierarchyFilters';

export default {
  mixins: [hierarchyFiltersMixin],
  props: {
    productKey: {
      type: String,
      required: true,
    },

    pricingGroupId: {
      type: String,
      required: true,
    },

    architectureGroupId: {
      type: String,
      required: true,
    },

    architectureSubGroupDescription: {
      type: String,
      required: false,
      default: '-',
    },

    scenarioKey: {
      type: String,
      required: true,
    },

    toolStoreGroupKey: {
      type: String,
      required: true,
    },

    isUnitManagerView: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      architectureGroupLevel,
      displayArchitectureSubGroupSplittingAttributes,
    };
  },

  computed: {
    ...mapState('clientConfig', ['toggleLogic']),
    ...mapGetters('hierarchy', ['getHierarchy']),
    ...mapState('architectureGroup', ['linePricingGroupForExpandedProduct', 'linePricingGroups']),
    ...mapState('gridView', [
      'expandedProduct',
      'isFilteringByArchitectureGroupEnabled',
      'loading',
    ]),
    ...mapState('filters', ['selectedPriceType']),
    ...mapGetters('competitorMetadata', ['competitorDescriptionToDisplayDescriptionMap']),

    architectureGroup() {
      return isEmpty(this.architectureGroupId)
        ? {
            levelEntryDescription: this.$t('gridView.noArchitectureGroup'),
            levelEntryKey: null,
          }
        : this.getHierarchy({
            levelEntryKey: this.architectureGroupId,
            level: architectureGroupLevel,
          })[0];
    },

    formattedArchitectureSubGroupDescription() {
      // treat empty string same as absence of data, replace with - manually.
      return this.architectureSubGroupDescription === ''
        ? '-'
        : this.architectureSubGroupDescription;
    },

    linePricingGroup() {
      return isEmpty(this.linePricingGroupForExpandedProduct)
        ? { linePricedGroupDescription: this.$t('gridView.noLinePricingGroup'), _id: null }
        : this.linePricingGroupForExpandedProduct;
    },
  },

  async created() {
    await Promise.all([
      this.fetchFullProductInfo({
        productKey: this.productKey,
        scenarioKey: this.scenarioKey,
        toolStoreGroupKey: this.toolStoreGroupKey,
      }),
      // pricingGroup and scenario selected already by the time this component is mounted. Just need to set AG based on product.
      this.setSelectedFilter({
        filterName: 'architectureGroup',
        filterValue: this.architectureGroupId,
      }),
      this.setSelectedFilter({
        filterName: 'scenario',
        filterValue: this.scenarioKey,
      }),
      this.getLinePricingGroupForProduct({
        productKey: this.productKey,
        toolStoreGroupKey: this.toolStoreGroupKey,
      }),
    ]);
  },

  destroyed() {
    this.setExpandedProduct(null);
    this.setLinePricingGroupForExpandedProduct(null);
  },

  async beforeDestroy() {
    // vuex only reads from local storage on page load, so state is inconsistent across tabs. with this hook, the state is reset when the component is closed.
    // e.g. in the current tab newTabOpenArchitectureRules may be true, so it needs to be explicitly set to false.
    // e.g. set Workpackage, open a new tab and change it. The old tab will have the old workpackage until the page is reloaded.
    await Promise.all([this.resetNewTabFilterState(), this.resetNewTabState()]);
  },

  methods: {
    ...mapActions('architectureGroup', [
      'getLinePricingGroupForProduct',
      'setLinePricingGroupForExpandedProduct',
      'setNewTabOpenLinePricingRules',
      'setNewTabOpenArchitectureRules',
      'setNewTabActiveLinePricingGroupIndex',
      'setNewTabOpenArchitectureSubGroupSplittingAttributes',
      'resetNewTabState',
    ]),
    ...mapActions('architectureDrivers', ['fetchArchitectureDrivers']),
    ...mapActions('filters', ['setSelectedFilter', 'resetNewTabFilterState']),
    ...mapActions('gridView', ['fetchFullProductInfo', 'setExpandedProduct']),

    async setNewTabFilters() {
      // set the filter so it's there when opening new tabs.
      await Promise.all([
        this.setSelectedFilter({
          filterName: 'newTabArchitectureGroup',
          filterValue: this.architectureGroupId,
        }),
        this.setSelectedFilter({
          filterName: 'newTabScenario',
          filterValue: this.scenarioKey,
        }),
        this.setSelectedFilter({
          filterName: 'newTabPricingGroup',
          filterValue: this.pricingGroupId,
        }),
        this.setSelectedFilter({
          filterName: 'newTabPriceType',
          filterValue: this.selectedPriceType,
        }),
        // to ensure filter state is clean. previous issue found as part of PRICE-1969.
        // opening architecture group first causes empty filter to be added on LPG screen, due to invalid AG filter applied in filter-panel.
        this.setSelectedFilter({
          filterName: 'newTabArchitectureGroupAttributeFilter',
          filterValue: [],
        }),
      ]);
    },

    addChartCoordinates(data) {
      return data.map(h => ({
        ...h,
        x: h.effectiveTimestamp,
        y: h.price,
      }));
    },

    async goToArchitectureGroup() {
      // only need newTabArchitectureGroupAttributeFilter when going to attributes page, for attribute-filter-panel.vue.
      await Promise.all([
        this.setNewTabFilters(),
        this.setNewTabOpenLinePricingRules(false),
        this.setNewTabOpenArchitectureRules(false),
        this.setNewTabOpenArchitectureSubGroupSplittingAttributes(null),
        // avoid interactions with other newtab filters by resetting state in vuex / local storage above
        this.setSelectedFilter({
          filterName: 'newTabArchitectureGroupAttributeFilter',
          filterValue: this.architectureGroupId,
        }),
      ]);

      const path = this.toggleLogic.displayV2Inputs
        ? `/inputs/attributes-v2/workpackage/${this.$route.params.workpackageId}`
        : '/inputs/attributes';
      const route = this.$router.resolve({ path });
      window.open(route.href, '_blank');
    },

    async goToLinePricingGroups() {
      let activeLinePricingGroupIndex = this.linePricingGroupForExpandedProduct
        .linePricingGroupIndex;
      // if activeLinePricingGroupIndex is not a valid index, set it to null
      if (activeLinePricingGroupIndex < 0) {
        activeLinePricingGroupIndex = null;
      }

      await Promise.all([
        this.setNewTabFilters(),
        this.setNewTabOpenLinePricingRules(true),
        this.setNewTabOpenArchitectureRules(false),
        this.setNewTabActiveLinePricingGroupIndex(activeLinePricingGroupIndex), // only needed to expand specific LPG
        this.setNewTabOpenArchitectureSubGroupSplittingAttributes(null),
      ]);
      const route = this.$router.resolve({
        path: `/settings/architecture/workpackage/${this.$route.params.workpackageId}`,
      });
      window.open(route.href, '_blank');
    },

    async goToArchitectureDrivers() {
      await Promise.all([
        this.setNewTabFilters(),
        this.setNewTabOpenLinePricingRules(false),
        this.setNewTabOpenArchitectureRules(true),
        this.setNewTabOpenArchitectureSubGroupSplittingAttributes(null),
      ]);
      const route = this.$router.resolve({
        path: `/settings/architecture/workpackage/${this.$route.params.workpackageId}`,
      });
      window.open(route.href, '_blank');
    },

    // different to other goTo funcs, sets ag-grid filter for v2-inputs screens only
    async goToArchitectureSubGroupSplittingAttributes() {
      await Promise.all([
        this.setNewTabFilters(),
        this.setNewTabOpenLinePricingRules(false),
        this.setNewTabOpenArchitectureRules(false),
        // productKey tsgKey set so ag-grid can load attribute data for this product,
        // then filter by sub group splitting attribute values
        this.setNewTabOpenArchitectureSubGroupSplittingAttributes(
          this.formattedArchitectureSubGroupDescription
        ),
        this.setSelectedFilter({
          filterName: 'newTabArchitectureGroupAttributeFilter',
          filterValue: this.architectureGroupId,
        }),
      ]);

      const route = this.$router.resolve({
        path: `/inputs/attributes-v2/workpackage/${this.$route.params.workpackageId}`,
      });
      window.open(route.href, '_blank');
    },

    async onToggleFilteringByArchitectureGroup() {
      this.$emit('toggleFilteringByArchitectureGroup', {
        isFilterEnabled: !!this.isFilteringByArchitectureGroupEnabled,
        architectureGroupAttribute: this.getArchitectureGroupFilterDefaults(),
        architectureGroupId: this.architectureGroupId,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.border-top {
  border-top: 0.1rem solid $pricing-grey;
}

.border-left {
  border-left: 0.1rem solid $pricing-grey;
}

.border-bottom {
  border-bottom: 0.1rem solid $pricing-grey;
}

.block {
  margin: 50px;
}

.goto-row-separator {
  border-bottom: 0.1rem solid $pricing-grey;
}

// TODO: move all .ok-btn classes into main.scss? Same class in 5 components now.
.row .col .ok-btn {
  color: $pricing-white;
  background-color: $btn-ok-color !important;
  border-color: $btn-ok-color;
}

.checkbox::v-deep {
  $scale: 0.65;
  transform: scale($scale);
  transform-origin: left;

  .v-input__prepend-outer {
    margin-right: 0;
  }
}
</style>
