<template>
  <tooltip
    :value="$t('gridView.tooltips.hasUnsavedKpiResults')"
    position="bottom"
    :disabled="!hasUnsavedKpiResults"
  >
    <v-btn
      depressed
      :loading="recalculatingAll"
      :disabled="isRecalculateDisabled"
      :class="['white--text', `${hasUnsavedKpiResults ? 'unsaved-kpis' : ''}`]"
      @click="runEngineForAllScenarios"
    >
      {{ $t('actions.recalculateAll') }}
      <v-icon v-once size="2rem">mdi-refresh</v-icon>
    </v-btn>
  </tooltip>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  props: {
    unsavedKpiResults: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('gridView', ['calculatingScenarioResults', 'recalculatingAll']),
    ...mapGetters('gridView', ['hasUnsavedKpiResultsForWorkpackage']),
    ...mapGetters('context', ['isWholesaleManager', 'isWholesaleAnalyst', 'isAdminLoggedIn']),

    isRecalculateDisabled() {
      return this.isPermissionDenied || this.calculatingScenarioResults || this.recalculatingAll;
    },

    isPermissionDenied() {
      return (this.isWholesaleManager || this.isWholesaleAnalyst) && !this.isAdminLoggedIn;
    },

    hasUnsavedKpiResults() {
      // Check for root level unsaved kpi results from store if not received as a param
      return this.unsavedKpiResults || this.hasUnsavedKpiResultsForWorkpackage;
    },
  },
  async created() {
    await this.checkRecalculatingAll();
  },
  methods: {
    ...mapActions('context', ['logout', 'resetState']),
    ...mapActions('gridView', ['runEngineForAllScenarios', 'checkRecalculatingAll']),
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.v-btn {
  background-color: $pricing-primary !important;
  color: white;

  // avoid rem, want button to be exactly the same regardless of parent container
  font-size: 14px !important;
  height: 24px !important;
  width: 140px !important;

  &.unsaved-kpis {
    background-color: $pricing-orange !important;

    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--loading) {
      background-color: $pricing-orange !important;
    }
  }
}
</style>
