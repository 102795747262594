// Libraries
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import Vuetify from 'vuetify/lib';
import VueMoment from 'vue-moment';
import draggable from 'vuedraggable';
import VJsoneditor from 'v-jsoneditor';
import AsyncComputed from 'vue-async-computed';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import numeral from 'numeral';
import VueHighcharts from 'vue-highcharts';

import 'numeral/locales/nl-nl';
import 'numeral/locales/en-gb';

// eslint-disable-next-line import/no-duplicates
import 'ag-grid-enterprise';
// eslint-disable-next-line import/no-duplicates
import { LicenseManager } from 'ag-grid-enterprise';

import App from '@/app.vue';
import store from '@/js/store';
import Axios from '@/js/axios';
import EventBus from '@/js/event-bus';
import router from '@/js/router';
import eventsMixin from '@/js/mixins/events';
import formatNumberMixin from '@/js/mixins/formatNumber';
import formatLocalisedDateMixin from '@/js/mixins/formatLocalisedDate';
import ensureNumberMixin from '@/js/mixins/ensureNumber';
import colors from '@/js/ow-colors';
import icons from '@/js/icons';
import vuePlugins from '@/js/utils/vue-plugins/index';

// Styles
import '@style/main.scss';
// import 'vuetify/dist/vuetify.min.css'; // TODO: use this when webpack config is changed to import it correctly

// add eventBus
Vue.use(EventBus);

const initialiseDatadog = context => {
  // Initialise Datadog RUM if not disabled for environment
  if (context.datadog.enableRum) {
    datadogRum.init({
      applicationId: context.datadog.applicationId,
      clientToken: context.datadog.clientToken,
      site: 'datadoghq.eu',
      service: 'pricing-webtool',
      env: context.env,
      version: context.versionTag,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackFrustrations: true,
      trackLongTasks: true,
      trackResources: true,
      enableExperimentalFeatures: ['clickmap'],
      allowedTracingUrls: [context.clientUrl],
    });
  }
  if (context.datadog.enableLogs) {
    datadogLogs.init({
      clientToken: context.datadog.clientToken,
      site: 'datadoghq.eu',
      service: 'pricing-webtool',
      env: context.env,
      version: context.versionTag,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });
  }
};

// Set ag-grid license
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-054150}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Wyman_Oliver_Inc}_is_granted_a_{Multiple_Applications}_Developer_License_for_{6}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{14}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{24_February_2025}____[v3]_[01]_MTc0MDM1NTIwMDAwMA==e41778debb5bf8aae8a4f23395613f6c'
);

// register components
Vue.component('Draggable', draggable);

// Extend Vue with libraries
Vue.use(Vuetify);
Vue.use(vuePlugins);
Vue.use(VueI18n);
Vue.use(VueMoment);
Vue.use(AsyncComputed);
Vue.use(VJsoneditor);
Vue.use(VueHighcharts);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.pricingPrimary,
        blue: colors.pricingBluePrimary,
        accent: colors.pricingBluePrimary,
      },
      dark: {
        primary: colors.pricingPrimary,
      },
    },
  },
  icons,
});

// Import all the available client components/filters/directives from this project
Vue.registerComponents(require.context('./src/js/components/', true, /(\.html|\.js|\.vue)$/));
Vue.registerComponents(require.context('./src/js/pages/', true, /(\.html|\.js|\.vue)$/));
Vue.registerDirectivesAndFilters(require.context('./src/js/directives/', true, /\.js$/));
Vue.registerDirectivesAndFilters(require.context('./src/js/filters/', true, /\.js$/));

Vue.use(Axios, { router, store });
Vue.use(VueRouter);

// Global mixins
Vue.mixin(eventsMixin);
Vue.mixin(formatNumberMixin);
Vue.mixin(formatLocalisedDateMixin);
Vue.mixin(ensureNumberMixin);

const setUpI18N = async () => {
  const {
    i18n: { numberFormats, dateFormats, i18nconfig },
  } = await store.dispatch('clientConfig/loadVueConfig');

  // TODO: This is a temporary step to let numeral work in V2 Inputs
  // PRICE-3574 will swap over to using numeral more widely and integrate it fully

  const { overrideNumberFormat } = i18nconfig;

  if (overrideNumberFormat === 'en') {
    // This sets decimal/ thousand settings but doesn't influence the currency symbol
    numeral.locale('en-gb');
  } else {
    numeral.locale('nl-nl');
  }

  return new VueI18n({
    locale: navigator.language, // browser language
    fallbackLocale: i18nconfig.fallbackLocale, // fallback language
    messages: {
      en: {
        footer: {
          manageCookiesButtonTitle: 'Manage Cookies',
          termsOfUse: 'Terms of Use',
          privacyNote: 'Privacy Notice',
          cookieNotice: 'Cookie Notice',
          solutionFrom: 'A solution from',
        },
      },
    }, // translations loaded before the user logs in,
    numberFormats, // number formats for all locales
    silentTranslationWarn: true,
    dateTimeFormats: dateFormats,
  });
};

Promise.resolve(store.dispatch('context/loadContext'))
  // Try to load current profile (get 401 otherwise and we navigate to the login page)
  .then(
    Promise.resolve(store.dispatch('context/loadUserContext')).finally(async () => {
      initialiseDatadog(store.state.context);

      // Start the Vue application
      const i18n = await setUpI18N();
      return new Vue({
        vuetify,
        i18n,
        router,
        store,
        el: '#vueholder',
        render: h => h(App),
      });
    })
  );
