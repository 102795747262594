import { findIndex } from 'lodash';

// This is hardcoded because we need to identify the workpackageId from the route before the router is initialized,
// at the time when initialiseState is run.
export default function getWorkPackageIdFromRoute() {
  const url = new URL(window.location.href);

  // Check if returnPath query parameter exists
  const returnPath = url.searchParams.get('returnPath');
  const pathToCheck = returnPath || url.pathname;

  // Extract path segments
  const pathSegments = pathToCheck.split('/').filter(segment => segment);
  const workpackageIndex = findIndex(pathSegments, segment => segment === 'workpackage');
  if (workpackageIndex !== -1 && workpackageIndex < pathSegments.length - 1) {
    return { workpackageId: pathSegments[workpackageIndex + 1] };
  }
  return {};
}
