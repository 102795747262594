import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "audit" },
    [
      _c(
        VContainer,
        [
          _c(
            VRow,
            [
              _c(VCol, [
                _c("h2", [
                  _vm._v(_vm._s(_vm.$t("audit.auditLogsExportHeader"))),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VRow,
            [
              _c(
                VCol,
                { attrs: { cols: "2" } },
                [
                  _c("audit-date-picker", {
                    attrs: { label: _vm.$t("audit.startDate") },
                    model: {
                      value: _vm.auditLogExportStartDate,
                      callback: function ($$v) {
                        _vm.auditLogExportStartDate = $$v
                      },
                      expression: "auditLogExportStartDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCol,
                { attrs: { cols: "2" } },
                [
                  _c("audit-date-picker", {
                    attrs: { label: _vm.$t("audit.endDate") },
                    model: {
                      value: _vm.auditLogExportEndDate,
                      callback: function ($$v) {
                        _vm.auditLogExportEndDate = $$v
                      },
                      expression: "auditLogExportEndDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCol,
                { attrs: { cols: "4" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { loading: _vm.loading },
                      on: { click: _vm.downloadAuditReport },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("actions.downloadAudit")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VRow,
            [
              _c(VCol, [
                _c("h2", [
                  _vm._v(_vm._s(_vm.$t("audit.analysisExportHeader"))),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VRow,
            [
              _c(
                VCol,
                { attrs: { cols: "2" } },
                [
                  _c("audit-date-picker", {
                    attrs: { label: _vm.$t("audit.startDate") },
                    model: {
                      value: _vm.productAnalysisExportStartDate,
                      callback: function ($$v) {
                        _vm.productAnalysisExportStartDate = $$v
                      },
                      expression: "productAnalysisExportStartDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCol,
                { attrs: { cols: "2" } },
                [
                  _c("audit-date-picker", {
                    attrs: { label: _vm.$t("audit.endDate") },
                    model: {
                      value: _vm.productAnalysisExportEndDate,
                      callback: function ($$v) {
                        _vm.productAnalysisExportEndDate = $$v
                      },
                      expression: "productAnalysisExportEndDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCol,
                { attrs: { cols: "2" } },
                [
                  _c(VCheckbox, {
                    attrs: { label: _vm.$t("audit.includeAllStoreGroups") },
                    model: {
                      value: _vm.includeAllStoreGroups,
                      callback: function ($$v) {
                        _vm.includeAllStoreGroups = $$v
                      },
                      expression: "includeAllStoreGroups",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCol,
                { attrs: { cols: "4" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { loading: _vm.loadingAnalysisDownload },
                      on: { click: _vm.downloadProductDateRange },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("actions.downloadAudit")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }