<template>
  <div class="nav-tab-wrapper">
    <v-btn
      v-if="!route.children && isWorkPackageSelected"
      :key="index"
      slot="activator"
      exact
      text
      :to="getFullPath(route.path)"
      class="text-none subheading nav-link"
      :class="[
        $route.fullPath.includes(route.path) ? 'active-link' : '',
        isNavbarHighlighted ? 'nav-link-dark' : '',
      ]"
      depressed
    >
      {{ $t(route.linkText) }}
    </v-btn>

    <v-menu v-if="route.children && isWorkPackageSelected" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          :key="index"
          exact
          text
          class="text-none subheading nav-link"
          :class="[
            $route.fullPath.includes(route.path) ? 'active-link' : '',
            isNavbarHighlighted ? 'nav-link-dark' : '',
          ]"
          depressed
          v-on="on"
        >
          {{ $t(route.linkText) }}
        </v-btn>
      </template>

      <v-list v-if="route.children">
        <v-list-item
          v-for="(item, i) in availableChildRoutes"
          :key="i"
          :to="getFullPath(route.path, '/', item.path)"
          dark
        >
          <v-list-item-title>{{ $t(item.linkText) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get, isNull } from 'lodash';

export default {
  props: {
    isWorkPackageSelected: {
      type: Boolean,
      default: () => false,
    },
    isNavbarHighlighted: {
      type: Boolean,
      default: () => false,
    },
    route: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },

  computed: {
    ...mapState('clientConfig', ['toggleLogic']),
    ...mapGetters('workpackages', ['getSelectedWorkpackage']),

    availableChildRoutes() {
      // Filter any childroutes where the feature flag is not set to true.
      const routes = this.route.children.filter(r => {
        const featureFlag = get(r, 'meta.featureFlag', null);
        if (isNull(featureFlag)) return true;

        const result = this.toggleLogic[featureFlag];
        return result;
      });

      return routes;
    },
    workpackageId() {
      // try to get the workpackageId from the route params, if it's not there, try to get it from the store
      return (
        (this.$route.params.workpackageId !== ':workpackageId' &&
          this.$route.params.workpackageId) ||
        get(this.getSelectedWorkpackage, '_id')
      );
    },
  },
  methods: {
    getFullPath(...params) {
      // as we composing navigation links from routes, we need to replace the :workpackageId placeholder with the actual workpackageId
      return params.join('').replace(':workpackageId', this.workpackageId);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/mixins/_nav-tab.scss';

.nav-link {
  @include nav-link-overrides();

  &.nav-link-dark {
    color: $pricing-white;
  }
}

.active-link {
  @include active-link-overrides();
}

.v-btn {
  @include v-btn-overrides();
}
.nav-tab-wrapper {
  height: 100%;

  > .nav-link {
    height: 100%;
  }
}

.theme--light.v-list {
  background-color: $sub-navigation-background;
  color: $sub-navigation-link;

  .v-list-item--link {
    color: $sub-navigation-link;
    height: 30px;
  }

  .v-list__tile {
    height: 3rem;
  }

  .v-list-item__title {
    font-size: 1.5rem;
  }

  .v-list-item--link:hover {
    background-color: $sub-navigation-hover;
    color: $sub-navigation-link;
  }
}
</style>
