import {
  differenceWith,
  find,
  isEmpty,
  invert,
  sortBy,
  get,
  isNaN,
  isNil,
  toLower,
  includes,
} from 'lodash';
import { descending } from '@enums/sort-direction';
import DataTypes from '@enums/data-types';

function sortStoreGroups(storeGroups, storeGroupOrder, sortKey) {
  // have to account for case where any or all storeGroupOrder could be missing
  const customSortedStoreGroups = [];
  const invertedStoreGroupOrder = invert(storeGroupOrder);
  const indexes = Object.keys(invertedStoreGroupOrder).map(k => parseInt(k, 10));
  // sort numerically! default js sort is alphabetical
  indexes
    .sort((a, b) => a - b)
    .forEach(i => {
      const localStoreGroup = find(storeGroups, {
        [sortKey]: invertedStoreGroupOrder[`${i}`],
      });

      if (!isEmpty(localStoreGroup)) {
        customSortedStoreGroups.push(localStoreGroup);
      }
    });

  // get all other store groups, and sort them alphabetically, after the explicitly ordered store groups in config.
  const theRest = differenceWith(
    storeGroups,
    customSortedStoreGroups,
    (a, b) => a[sortKey] === b[sortKey]
  );

  return [...customSortedStoreGroups, ...sortBy(theRest, [sortKey])];
}

export const compareToolStoreGroup = (a, b, storeGroupOrder) => {
  const aStoreGroupOrder = storeGroupOrder[a] || 0;
  const bStoreGroupOrder = storeGroupOrder[b] || 0;
  // sort alphabetically
  // if it returns a negative value, the value in a will be ordered before b.
  // if it returns 0, the ordering of a and b won’t change.
  // if it returns a positive value, the value in b will be ordered before a.
  if (!aStoreGroupOrder && !bStoreGroupOrder) return `${a}`.localeCompare(b);
  if (!aStoreGroupOrder) return 1;
  if (!bStoreGroupOrder) return -1;
  return aStoreGroupOrder - bStoreGroupOrder;
};

/**
 * Returns a function that defines the sort order: sort by toolStoreGroupDescription first, then sort by specific field inside of tool store group.
 * @param { sortByField: string, sortDirection: string, dataType: string, storeGroupOrderConfig: Object, compareByAbsoluteValues: boolean }
 * sortByField - the field by which items will be sorted inside of a tool store group.
 * sortDirection - sorting direction (data/enums/sort-direction).
 * dataType - the type of data stored in the sortByField  (data/enums/data-types).
 * storeGroupOrderConfig - store groups order (store/clientConfig/storeGroupOrderConfig).
 * compareByAbsoluteValues - indicates whether absolute values need to be compared.
 * @returns a function that defines the sort order.
 */
export const getCompareInsideToolStoreGroupFunc = ({
  sortByField,
  sortDirection,
  dataType,
  storeGroupOrderConfig,
  compareByAbsoluteValues,
}) => {
  const defaultValues = {
    [DataTypes.str]: '',
    [DataTypes.number]: -Infinity,
  };
  const isDesc = sortDirection === descending;
  const defaultVal = compareByAbsoluteValues ? 0 : defaultValues[dataType];
  return (a, b) => {
    let aVal = get(a, sortByField, defaultVal);
    let bVal = get(b, sortByField, defaultVal);

    aVal = isNil(aVal) || isNaN(aVal) ? defaultVal : aVal;
    bVal = isNil(bVal) || isNaN(bVal) ? defaultVal : bVal;

    // sorting strings lexigraphically so compare lowercase
    if (dataType === DataTypes.str) {
      aVal = toLower(aVal);
      bVal = toLower(bVal);
    }

    // compare absolute values
    if (compareByAbsoluteValues) {
      aVal = Math.abs(aVal);
      bVal = Math.abs(bVal);
    }

    if (a.toolStoreGroupDescription !== b.toolStoreGroupDescription) {
      return compareToolStoreGroup(
        a.toolStoreGroupDescription,
        b.toolStoreGroupDescription,
        storeGroupOrderConfig
      );
    }

    if (!isDesc) return aVal < bVal ? -1 : 1;
    return bVal < aVal ? -1 : 1;
  };
};

/**
 * Returns a function that defines the sort order: sort by some field first, then sort by order defined in storeGroupOrderConfig.
 * @param {string} sortByField - the field by which items will be sorted inside of a tool store group.
 * @param {string} sortDirection - sorting direction (data/enums/sort-direction).
 * @param {string} dataType - the type of data stored in the sortByField  (data/enums/data-types).
 * @param {Object} storeGroupOrderConfig - store groups order (store/clientConfig/storeGroupOrderConfig).
 * @param {Boolean} compareByAbsoluteValues - indicates whether absolute values need to be compared
 * @param {Boolean} compareByToolStoreGroup - indicates whether the results should be sorted according to storeGroupOrderConfig.
 * @param {Boolean} useArticle - indicates whether we need to extract data from product.article.
 * @returns a function that defines the sort order.
 */
export const getCompareProductsFunc = ({
  sortByField,
  sortDirection,
  dataType,
  storeGroupOrderConfig,
  compareByAbsoluteValues,
  compareByToolStoreGroup,
  useArticle = false,
  readFromDatabricks = false,
}) => {
  const defaultValues = {
    [DataTypes.str]: '',
    [DataTypes.number]: -Infinity,
  };
  const isDesc = sortDirection === descending;
  const defaultVal = compareByAbsoluteValues ? 0 : defaultValues[dataType];
  const sortByToolStoreGroupColumns = [
    'productKey',
    'productKeyDisplay',
    'productName',
    'productDescription',
  ];

  // TODO PRICE-1459: Reconsider this check as soon as product-impact-list is moved to AG Grid
  // This function is used to sort data for two different tables (with different data and APIs),
  // Make sure that sorting function can retrieve the correct data according to what is being sorted:
  // - for table in grid-view, the required fields are in the product object
  // - for table in product-impact-list, the data is in the product.article object
  const columnKey = useArticle ? sortByField.replace('article.', '') : sortByField;
  const checkProductKey = (a, b) =>
    useArticle ? a.article.productKey === b.article.productKey : a.productKey === b.productKey;

  return (a, b) => {
    let aVal = get(a, sortByField, defaultVal);
    let bVal = get(b, sortByField, defaultVal);

    if (readFromDatabricks) {
      // If sorting on a competitor field, path should match competitors.competitorKey.competitorField
      const [field1, field2, field3] = sortByField.split('.');
      if (field1 === 'competitors') {
        aVal = get(
          get(a, field1, []).find(competitor => competitor.competitorKey.toString() === field2),
          field3,
          defaultVal
        );
        bVal = get(
          get(b, field1, []).find(competitor => competitor.competitorKey.toString() === field2),
          field3,
          defaultVal
        );
      }
    }

    aVal = isNil(aVal) || isNaN(aVal) ? defaultVal : aVal;
    bVal = isNil(bVal) || isNaN(bVal) ? defaultVal : bVal;

    // sorting strings lexigraphically so compare lowercase
    if (dataType === DataTypes.str) {
      aVal = toLower(aVal);
      bVal = toLower(bVal);
    }

    // compare absolute values
    if (compareByAbsoluteValues) {
      aVal = Math.abs(aVal);
      bVal = Math.abs(bVal);
    }

    if (
      compareByToolStoreGroup &&
      includes(sortByToolStoreGroupColumns, columnKey) &&
      checkProductKey(a, b) &&
      aVal === bVal
    ) {
      return compareToolStoreGroup(
        a.toolStoreGroupDescription,
        b.toolStoreGroupDescription,
        storeGroupOrderConfig
      );
    }

    // productKeyDisplay is a string field but we want to sort the items
    // numerically (e.g. so that 99 is before 101)
    // if non-numeric characters are added in the future we will have to
    // update this so sorting works
    if (sortByField === 'productKeyDisplay') {
      aVal = parseInt(aVal, 10);
      bVal = parseInt(bVal, 10);
    }

    if (!isDesc) return aVal < bVal ? -1 : 1;
    return bVal < aVal ? -1 : 1;
  };
};

export default sortStoreGroups;
