import wholesalePage from './wholesale.vue';
import { wholesale as wholesalePageIndex } from '@enums/page-index';
import { displayWholesaleTab } from '@enums/feature-flags';

const routes = [
  {
    path: '/wholesale/workpackage/:workpackageId',
    name: 'wholesale',
    id: 'wholesale',
    component: wholesalePage,
    index: wholesalePageIndex,
    linkText: 'linkText.wholesale',
    meta: {
      featureFlag: displayWholesaleTab,
      selectedWorkpackageRequired: true,
      title: 'tabText.wholesale',
    },
  },
];

export default routes;
