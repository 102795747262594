import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "contents" } },
    [
      _c(
        "tr",
        {
          class: [
            "scenario-selector-row",
            _vm.isCandidateScenario ? "selected" : "",
          ],
        },
        [
          _c("alert-dialog", {
            attrs: {
              "is-open": _vm.isDeleteDialogOpen,
              "ok-btn-text": _vm.$t("actions.delete"),
            },
            on: {
              onOk: _vm.runDeleteScenario,
              onCancel: _vm.closeDeleteDialog,
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _vm._v(_vm._s(_vm.$t("dialogs.deleteScenario.header"))),
                  ]
                },
                proxy: true,
              },
              {
                key: "body",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("dialogs.deleteScenario.body")) +
                        "\n        "
                    ),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.scenarioToDelete
                            ? _vm.scenarioToDelete.scenarioDescription
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "td",
            {
              staticClass: "border-right",
              class: { "border-bottom": _vm.displayBorderUnderName },
            },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    VCol,
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        VRadioGroup,
                        {
                          model: {
                            value: _vm.isCandidateScenario,
                            callback: function ($$v) {
                              _vm.isCandidateScenario = $$v
                            },
                            expression: "isCandidateScenario",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: {
                              value: true,
                              disabled:
                                _vm.isCandidateScenario ||
                                !_vm.isEditable ||
                                _vm.isCalculating,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openModal()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "scenario-name" },
                        [
                          _c("tooltipped-truncated-field", {
                            attrs: {
                              text: _vm.scenario.scenarioDescription,
                              "truncation-length":
                                _vm.scenarioDescriptionTruncationLength,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VDialog,
                        {
                          attrs: { width: "40rem" },
                          model: {
                            value: _vm.confirmationDialog,
                            callback: function ($$v) {
                              _vm.confirmationDialog = $$v
                            },
                            expression: "confirmationDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VCardTitle,
                                {
                                  staticClass: "headline lighten-2",
                                  attrs: { "primary-title": "" },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "gridView.changeScenario.modalTitle"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                VCardText,
                                [
                                  _c(
                                    "div",
                                    { staticClass: "dialog-copy pt-5 pb-7" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "gridView.changeScenario.modalCopy"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VCardActions,
                                    [
                                      _c(VSpacer),
                                      _vm._v(" "),
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "cancel-btn",
                                          attrs: {
                                            outlined: "",
                                            small: "",
                                            text: "",
                                            depressed: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.confirmationDialog = false
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("actions.cancel")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "primary",
                                          attrs: { small: "", depressed: "" },
                                          on: { click: _vm.changeScenario },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("actions.submit")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { attrs: { cols: "1" } },
                    [
                      _c(
                        "tooltip",
                        {
                          attrs: {
                            value: _vm.$t("gridView.tooltips.recalculating"),
                          },
                        },
                        [
                          _vm.isCalculating
                            ? _c(VProgressCircular, {
                                attrs: {
                                  size: "20",
                                  width: "2",
                                  color: "primary",
                                  indeterminate: "",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { attrs: { cols: "1" } },
                    [
                      _vm.canDelete
                        ? _c(
                            VBtn,
                            {
                              staticClass: "mr-2 btn-delete",
                              attrs: {
                                color: "primary",
                                small: "",
                                disabled: _vm.deleting || _vm.isCalculating,
                                loading: _vm.deleting,
                                depressed: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openDeleteDialog(_vm.scenario)
                                },
                              },
                            },
                            [_vm._m(0)],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    {
                      staticClass: "margin-lever-container",
                      attrs: { cols: "1" },
                    },
                    [
                      _c(
                        "tooltip",
                        {
                          attrs: {
                            value: _vm.$t(
                              "gridView.tooltips.marginLeverUpdate"
                            ),
                          },
                        },
                        [
                          _c(VTextField, {
                            staticClass: "d-flex flex-row-reverse",
                            attrs: {
                              value: _vm.formatNumber({
                                number: _vm.scenario.marginLever,
                                format: _vm.numberFormats.priceFormat,
                              }),
                              type: "text",
                              disabled: !_vm.isEditable,
                              rules: _vm.rules.marginLever,
                            },
                            on: {
                              keypress: function ($event) {
                                return _vm.ensureDecimal($event, true)
                              },
                              focus: function ($event) {
                                _vm.previousFieldValue = $event.target.value
                              },
                              change: function ($event) {
                                return _vm.marginLeverChange(
                                  $event,
                                  _vm.scenario.marginLever
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("td", { staticClass: "text-right border-bottom" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.formatNumber({
                    number: _vm.selectedPeriodAggregation.salesMAT / 1000,
                    format: _vm.numberFormats.integer,
                    zeroAsDash: true,
                  })
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("td", { staticClass: "text-right border-bottom" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.formatNumber({
                    number: _vm.selectedPeriodAggregation.promoShare,
                    format: _vm.numberFormats.percent,
                    zeroAsDash: true,
                  })
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "td",
            {
              staticClass: "text-right highlight border-bottom",
              class: _vm.formatNumberColourClass(_vm.impacts.sales),
            },
            [
              _c(
                "tooltip",
                {
                  attrs: {
                    value: {
                      [_vm.$t(
                        `gridView.${
                          _vm.showRegularImpact
                            ? "regularImpactInYear"
                            : "totalImpactInYear"
                        }`
                      )]: _vm.formatNumber({
                        number: _vm.impacts.salesInYear,
                        format: _vm.numberFormats.integer,
                        zeroAsDash: true,
                      }),
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.formatNumber({
                          number: _vm.impacts.sales,
                          format: _vm.numberFormats.integer,
                          zeroAsDash: true,
                        })
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "td",
            {
              staticClass: "text-right highlight border-right border-bottom",
              class: _vm.formatNumberColourClass(_vm.impacts.cost, true),
            },
            [
              _c(
                "tooltip",
                {
                  attrs: {
                    value: {
                      [_vm.$t("gridView.costImpactInYear")]: _vm.formatNumber({
                        number: -_vm.impacts.costInYear,
                        format: _vm.numberFormats.integer,
                        zeroAsDash: true,
                      }),
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.formatNumber({
                          number: -_vm.impacts.cost,
                          format: _vm.numberFormats.integer,
                          zeroAsDash: true,
                        })
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("td", { staticClass: "text-right border-bottom" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.formatNumber({
                    number: _vm.selectedPeriodAggregation.marginProposed,
                    format: _vm.extraDecimalPlacesForMarginDelta
                      ? _vm.numberFormats.percentWithExtraDecimalPlaces
                      : _vm.numberFormats.percent,
                    zeroAsDash: true,
                  })
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "td",
            {
              staticClass: "text-right highlight border-right border-bottom",
              class: _vm.formatNumberColourClass(
                _vm.selectedPeriodAggregation.marginDelta
              ),
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.formatNumber({
                      number: _vm.selectedPeriodAggregation.marginDelta,
                      format: _vm.extraDecimalPlacesForMarginDelta
                        ? _vm.numberFormats.percentWithExtraDecimalPlaces
                        : _vm.numberFormats.percent,
                      zeroAsDash: true,
                    })
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.readFromDatabricks
            ? [
                _vm._l(_vm.selectedCompetitors, function ({ competitorKey }) {
                  return [
                    _c("td", { staticClass: "text-right border-bottom" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.formatNumber({
                              number: null,
                              format: _vm.numberFormats.priceFormat,
                              zeroAsDash: true,
                            })
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right border-bottom" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.formatNumber({
                              number:
                                _vm.selectedWeightValues[competitorKey]
                                  .competitorLiveDistance,
                              format: _vm.numberFormats.percent,
                              zeroAsDash: true,
                            })
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right border-bottom" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.formatNumber({
                              number:
                                _vm.selectedWeightValues[competitorKey]
                                  .competitorProposedDistance,
                              format: _vm.numberFormats.percent,
                              zeroAsDash: true,
                            })
                          ) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass:
                          "text-right highlight border-right border-bottom",
                        class: _vm.formatNumberColourClass(
                          _vm.selectedWeightValues[competitorKey]
                            .competitorDeltaVsPrev
                        ),
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber({
                                number:
                                  _vm.selectedWeightValues[competitorKey]
                                    .competitorDeltaVsPrev,
                                format: _vm.numberFormats.percent,
                                zeroAsDash: true,
                              })
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                }),
              ]
            : [
                _c("td", { staticClass: "text-right border-bottom" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.formatNumber({
                          number: null,
                          format: _vm.numberFormats.priceFormat,
                          zeroAsDash: true,
                        })
                      ) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right border-bottom" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.formatNumber({
                          number:
                            _vm.selectedWeightValues.competitor1LiveDistance,
                          format: _vm.numberFormats.percent,
                          zeroAsDash: true,
                        })
                      ) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right border-bottom" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.formatNumber({
                          number:
                            _vm.selectedWeightValues
                              .competitor1ProposedDistance,
                          format: _vm.numberFormats.percent,
                          zeroAsDash: true,
                        })
                      ) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "text-right highlight border-right border-bottom",
                    class: _vm.formatNumberColourClass(
                      _vm.selectedWeightValues.competitor1DeltaVsPrev
                    ),
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.formatNumber({
                            number:
                              _vm.selectedWeightValues.competitor1DeltaVsPrev,
                            format: _vm.numberFormats.percent,
                            zeroAsDash: true,
                          })
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("td", { staticClass: "text-right border-bottom" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.formatNumber({
                          number: null,
                          format: _vm.numberFormats.priceFormat,
                          zeroAsDash: true,
                        })
                      ) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right border-bottom" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.formatNumber({
                          number:
                            _vm.selectedWeightValues.competitor2LiveDistance,
                          format: _vm.numberFormats.percent,
                          zeroAsDash: true,
                        })
                      ) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right border-bottom" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.formatNumber({
                          number:
                            _vm.selectedWeightValues
                              .competitor2ProposedDistance,
                          format: _vm.numberFormats.percent,
                          zeroAsDash: true,
                        })
                      ) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass:
                      "group-end text-right highlight border-right border-bottom",
                    class: _vm.formatNumberColourClass(
                      _vm.selectedWeightValues.competitor2DeltaVsPrev
                    ),
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.formatNumber({
                            number:
                              _vm.selectedWeightValues.competitor2DeltaVsPrev,
                            format: _vm.numberFormats.percent,
                            zeroAsDash: true,
                          })
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ],
          _vm._v(" "),
          _c(
            "td",
            { staticClass: "text-right border-right border-bottom" },
            [
              _c(
                "tension-chart",
                _vm._b({}, "tension-chart", _vm.tensionChartProps, false)
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "td",
            { staticClass: "text-right border-bottom" },
            [
              _c(
                VChip,
                { staticClass: "remove-margin", attrs: { small: "" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.formatNumber({
                          number: _vm.scenario.numPriceChanges,
                          format: _vm.numberFormats.integer,
                          nullAsDash: true,
                        })
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("td", { staticClass: "alert-cell" }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "feature-toggle",
        { attrs: { toggle: _vm.displayStoreGroupPenalty } },
        [
          _c("store-group-aggregations", {
            key: _vm.scenario._id,
            attrs: {
              "parent-id": _vm.levelEntryKey,
              level: _vm.hierarchy.pricingGroupLevel,
              "scenario-key": _vm.scenario.scenarioKey,
            },
          }),
          _vm._v(" "),
          _c("tr", { staticClass: "details-row" }, [
            _c("td", { staticClass: "border-bottom", attrs: { colspan: "1" } }),
            _vm._v(" "),
            _c("td", {
              staticClass: "border-bottom",
              attrs: { colspan: "14" },
            }),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "border-bottom", attrs: { colspan: "2" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "clickable my-auto",
                    on: { click: _vm.toggleDisplayStoreGroupAggregations },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.storeGroupExpanded
                            ? _vm.$t("actions.close")
                            : _vm.$t("actions.details")
                        ) +
                        "\n          "
                    ),
                    _c("i", {
                      class: [
                        _vm.storeGroupExpanded
                          ? "mdi mdi-chevron-up"
                          : "mdi mdi-chevron-down",
                      ],
                    }),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("td", { staticClass: "alert-cell" }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, { attrs: { small: "" } }, [_vm._v(" fa-trash ")])
  },
]
render._withStripped = true

export { render, staticRenderFns }