<template>
  <div v-show="hasSelectedWorkpackage">
    <attribute-filter-panel
      :key="'attribute-filter-panel-' + isFilteringByArchitectureGroupEnabled"
      :filter-rules="retailAttributesFilter"
      enable-hierarchy-filters
      title-localisation="attributes.filters.filterByAttributes"
      filter-count-localisation="attributes.filters.numApplied"
      @attributeFilterChange="setGridViewFilter"
    />

    <div class="position-relative">
      <v-overlay v-if="isLoading" absolute opacity="0.5" color="white" z-index="8">
        <v-progress-circular indeterminate color="primary" size="32" />
      </v-overlay>
      <div class="d-flex align-center justify-center flex-column">
        <v-fade-transition>
          <v-row v-if="noProductsMatchingFilters && recalculatingAll" key="recalculating">
            <span class="notification-text">{{
              $t('gridView.noProductsWhileRecalculatingAll')
            }}</span>
          </v-row>
          <v-row v-else-if="noProductsMatchingFilters" key="empty-results">
            <span class="notification-text mb-5">{{ $t('gridView.noResultsToDisplay') }}</span>
          </v-row>
        </v-fade-transition>
      </div>

      <div class="hierarchy__container">
        <table class="hierarchy__table">
          <component
            :is="item.component"
            v-for="item in tableRows"
            :key="item.metaId"
            v-bind="item.data"
            @releaseCategory="releaseCategory"
            @releasePricingGroup="releasePG"
            @pricingSpecialistPricingGroupApproval="pricingSpecialistPricingGroupApproval"
            @pricingSpecialistCategoryApproval="pricingSpecialistCategoryApproval"
            @categoryManagerPricingGroupApproval="categoryManagerPricingGroupApproval"
            @categoryManagerCategoryApproval="categoryManagerCategoryApproval"
            @unitApproval="approveUnit"
            @toggleFilteringByArchitectureGroup="onToggleFilteringByArchitectureGroup"
          />
        </table>
      </div>
      <!-- Confirm dialog -->
      <alert-dialog
        :is-open="isReleaseConfirmDialogueOpen"
        :ok-btn-text="$t('actions.ok')"
        @onOk="releasePricingGroup(true)"
        @onCancel="releasePricingGroup(false)"
      >
        <template v-if="categoryToRelease" v-slot:header>
          {{ $t('dialogs.releaseDialogs.headerCategory') }}
        </template>
        <template v-else v-slot:header>
          {{ $t('dialogs.releaseDialogs.headerPricingGroup') }}
        </template>
        <template v-if="categoryToRelease" v-slot:body>
          {{ $t('dialogs.releaseDialogs.bodyCategory') }}
          <strong>{{ categoryToRelease ? categoryToRelease.name : '' }}</strong>
        </template>
        <template v-else v-slot:body>
          {{ $t('dialogs.releaseDialogs.bodyPricingGroup') }}
          <strong>{{ pricingGroupToRelease ? pricingGroupToRelease.name : '' }}</strong>
        </template>
      </alert-dialog>
      <grid-view-add-scenario-dialog />
    </div>
  </div>
</template>

<script>
import { debounce, fill, findIndex, reduce, slice, get, sortBy, values, isEmpty } from 'lodash';
import { createNamespacedHelpers, mapActions, mapState } from 'vuex';
import to from 'await-to-js';
import { getGroupedAggregations, getSingleAggregations } from '@enums/feature-flags';
import { unitLevel, pricingGroupLevel, categoryLevel } from '@enums/hierarchy';
import { mapAggregations } from '@sharedModules/data/utils/time-flexible-utils';
import sapExportUtilsMixin from '../../../mixins/sapExportUtils';
import clientConfig from '@sharedModules/config/client';

const { mapGetters } = createNamespacedHelpers('gridView');
const { mapState: mapGridViewState } = createNamespacedHelpers('gridView');
const { mapGetters: mapWorkPackageGetters } = createNamespacedHelpers('workpackages');
const { mapGetters: mapHierarchyGetters } = createNamespacedHelpers('hierarchy');
const { mapState: mapContextState } = createNamespacedHelpers('context');
const { mapActions: mapHierarchyActions } = createNamespacedHelpers('hierarchy');
const { mapGetters: mapAttributesGetters } = createNamespacedHelpers('attributes');

export default {
  mixins: [sapExportUtilsMixin],

  props: {
    units: Array,
    isUnitManagerView: Boolean,
  },

  events: {
    onNewCandidateScenarioElected() {
      this.fetchAggregations();
    },

    onToggleExpansion({ parentId, level, beingExpanded }) {
      // remove all items below the expanded level
      this.setExpandedHierarchyLevelItems({
        newExpandedHierarchy: fill([...this.expandedHierarchyLevelItems], null, level + 1),
      });
      // PG level and unitMangerView filters state resets in onExpandProductView

      if (level !== pricingGroupLevel && !this.isUnitManagerView) {
        // remove pricing group selection on expansion change
        this.resetState();
      }

      // this means the are collapsing an expanded row
      if (!beingExpanded) return;
      const requests = [];

      if (this.toggleLogic[getSingleAggregations]) {
        // trigger a dispatch for the old aggregations
        requests.push(
          this.fetchAggregatedHierarchyLevelItems({
            aggregationLevel: level + 1,
            parentId,
          })
        );
      }

      if (level === pricingGroupLevel || (level === unitLevel && this.isUnitManagerView)) {
        // in case a user has updated the hierarchies in another tab
        requests.push(this.fetchHierarchy());
      }
      // don't remove "to", it's required to prevent printing of "unhandled promise rejection" errors
      to(Promise.all(requests));

      const newExpandedHierarchy = [...this.expandedHierarchyLevelItems];
      newExpandedHierarchy[level + 1] = parentId;
      this.setExpandedHierarchyLevelItems({ newExpandedHierarchy });
    },

    onExpandProductView({ levelEntryKey }) {
      if (this.selectedLevelEntryKey === levelEntryKey) this.resetState();
      else {
        this.setSelectedFilter({ filterName: 'selectedLevelEntryKey', filterValue: levelEntryKey });
        if (!this.isUnitManagerView) {
          this.setSelectedFilter({ filterName: 'pricingGroup', filterValue: levelEntryKey });
        }
      }
    },

    async onCompetitorSelectionChanged() {
      this.updateGridView();
    },

    async onHistoricalPeriodSelectionChanged() {
      if (this.readFromDatabricks) {
        this.updateGridView();
      }
    },
  },

  data() {
    return {
      pricingGroupToRelease: null,
      isReleaseConfirmDialogueOpen: false,
      loadingHierarchy: false,
    };
  },

  computed: {
    ...mapState('gridView', [
      'hierarchy',
      'rootHierarchyLevelItemId',
      'expandedHierarchyLevelItems',
      'pricingGroupsToRelease',
      'categoryToRelease',
      'selectedHistoricalPeriod',
      'loading',
      'loadingRecalculateAllResults',
      'groupedHierarchy',
    ]),
    ...mapState('workpackages', ['selectedUnitLevelEntryKey']),
    ...mapState('clientConfig', {
      toggleLogic: 'toggleLogic',
      hierarchyConfig: 'hierarchy',
    }),
    ...mapContextState(['profile']),
    ...mapGridViewState([
      'isFilteringByArchitectureGroupEnabled',
      'isCachedResult',
      'recalculatingAll',
    ]),
    ...mapGetters([
      'hierarchyLevelItems',
      'hierarchyKey',
      'aggregationsLoading',
      'groupedHierarchyKey',
      'previousGroupedHierarchyKey',
      'readFromDatabricks',
    ]),
    ...mapWorkPackageGetters(['hasSelectedWorkpackage']),
    ...mapHierarchyGetters(['getHierarchy']),
    ...mapAttributesGetters(['toolStoreGroupGlobalAttributeKey']),
    ...mapState('filters', ['selectedLevelEntryKey', 'retailAttributesFilter']),
    ...mapState('storeGroupRelationships', ['storegroupKeyNameMap']),

    noProductsMatchingFilters() {
      return isEmpty(this.tableRows) && !this.isLoading;
    },

    isLoading() {
      return (
        this.loading ||
        this.loadingHierarchy ||
        this.aggregationsLoading ||
        (this.loadingRecalculateAllResults && !this.recalculatingAll)
      );
    },

    orderedHierarchyLevelItems() {
      // FIXME: come up with nicer way to achieve this
      // slicing here to remove AH entry as we want to render the headers after it
      const sliceEnd = this.isUnitManagerView ? unitLevel + 1 : pricingGroupLevel + 1; // +1 as slice
      return reduce(
        slice(this.expandedHierarchyLevelItems, 0, sliceEnd),
        (orderedItems, parentId, level) => {
          let key;
          let items;
          if (this.toggleLogic[getSingleAggregations]) {
            key = this.hierarchyKey({ level, parentId });
            items = this.hierarchy[key];
          }
          if (this.toggleLogic[getGroupedAggregations] && isEmpty(items)) {
            key = this.groupedHierarchyKey();
            items = get(this.groupedHierarchy, [key, level]);
            if (!items) {
              // get cached data for previous competitors before new ones are loaded on competitor dropdown change
              // so that screen don't jump
              // overlay is shown on top of previous data before new one is loaded
              key = this.previousGroupedHierarchyKey();
              items = get(this.groupedHierarchy, [key, level], {});
            }
            items = values(items).filter(
              // Also filter out the hierarchyItems without PG hierarchies.
              // TODO: the case above shouldn't exist, need to figure out the root cause
              a => a.parentId === parentId && a._id
            );
          }

          const sortedItems = sortBy(items, [item => item.name.toLowerCase()]);

          if (!sortedItems) return orderedItems;
          if (level === this.hierarchyConfig.rootHierarchyLevel) return [...sortedItems];

          const filteredItems = this.isUnitManagerView
            ? sortedItems.filter(({ levelEntryKey }) => this.units.includes(levelEntryKey))
            : sortedItems;
          const parentIx = findIndex(orderedItems, { _id: parentId });

          // if we don't have a parent for the selection ignore.
          if (parentIx === -1) {
            return orderedItems;
          }
          orderedItems.splice(parentIx + 1, 0, ...filteredItems);
          return orderedItems;
        },
        []
      );
    },

    tableRows() {
      return reduce(
        this.orderedHierarchyLevelItems,
        (flatHierarchy, item, ix) => {
          item.toolStoreGroupDescription = this.storegroupKeyNameMap[item.toolStoreGroupKey];
          item._id = get(item, ['_id', 'levelGroupId'], item._id);
          if (ix === 0)
            flatHierarchy.push({
              metaId: 'headers',
              component: 'hierarchy-table-headers',
              data: null,
            });
          const mapAggregationsBySelectedHistoricalPeriod = mapAggregations(
            this.selectedHistoricalPeriod
          );
          const aggregations = this.readFromDatabricks
            ? item
            : mapAggregationsBySelectedHistoricalPeriod(item);
          const staticFields = {
            _id: item._id,
            parentId: item.parentId,
            name: item.name,
            level: item.level,
            levelEntryKey: item.levelEntryKey,
            workpackageId: item.workpackageId,
            released: item.released,
            approvalStatus: item.approvalStatus,
          };
          flatHierarchy.push({
            metaId: item._id,
            component: 'hierarchy-level-item',
            data: {
              isUnitManagerView: this.isUnitManagerView,
              expandedHierarchyLevelItems: this.expandedHierarchyLevelItems,
              ...aggregations,
              ...staticFields,
            },
          });

          if (item._id === this.selectedLevelEntryKey) {
            flatHierarchy.push({
              metaId: `products::${this.selectedLevelEntryKey}`,
              component: 'hierarchy-product-view',
              data: {
                levelEntryKey: this.selectedLevelEntryKey,
                isUnitManagerView: this.isUnitManagerView,
              },
            });
          }

          return flatHierarchy;
        },
        []
      );
    },
  },

  async created() {
    this.loadingHierarchy = true;

    // fetch the aggregations from root -> pg level
    await Promise.all([
      this.fetchRootHierarchyLevelItemId(),
      this.fetchAllScenarioDescriptionsForWorkpackage(), // used for notifications
      ...(this.toggleLogic[getGroupedAggregations]
        ? [this.fetchGroupedAggregatedHierarchyLevelItems({ refreshCache: false })]
        : []),
    ]);

    // store the parentId of each hierarchyLevelItem where the array ix is the aggregation level
    await this.setExpandedHierarchyLevelItems({ newExpandedHierarchy: null });

    const fetchAggregations = [this.fetchHierarchy()];
    if (this.toggleLogic[getSingleAggregations]) {
      fetchAggregations.push(
        ...[
          this.fetchAggregatedHierarchyLevelItems(),
          this.fetchAggregatedHierarchyLevelItems({ aggregationLevel: unitLevel }),
        ]
      );
      // a unit has been clicked on the workpackage screen
      if (this.selectedUnitLevelEntryKey && !this.isUnitManagerView) {
        fetchAggregations.push(
          this.fetchAggregatedHierarchyLevelItems({
            aggregationLevel: categoryLevel,
            parentId: this.selectedUnitLevelEntryKey,
          })
        );
      }
    }

    // don't remove "to", it's required to prevent printing of "unhandled promise rejection" errors
    await to(Promise.all(fetchAggregations));

    if (this.isCachedResult) {
      // fire off another request to get the udpated aggregations
      this.fetchFreshGridviewAggregations();
    }

    this.getRecalculatingSpecificPricingGroups();
    this.globalEmit('hierarchy-refreshed');
    this.loadingHierarchy = false;
  },

  beforeDestroy() {
    // reset for when changing between unit manager and normal view
    if (this.isUnitManagerView) {
      this.setSelectedFilter({ filterName: 'selectedLevelEntryKey', filterValue: null });
      this.resetExpandedHierarchyLevels();
    }
    if (!this.$route.path.includes('/pricing/')) {
      this.cancelTimeFlexibleAggregationRequests();
    }
  },

  methods: {
    ...mapActions('gridView', [
      'fetchAggregatedHierarchyLevelItems',
      'fetchGroupedAggregatedHierarchyLevelItems',
      'fetchRootHierarchyLevelItemId',
      'fetchItems',
      'setExpandedHierarchyLevelItems',
      'resetPricingGroupsToRelease',
      'setPricingGroupsToRelease',
      'setCategoryToRelease',
      'fetchAggregations',
      'resetExpandedHierarchyLevels',
      'getAlertCounts',
      'toggleFilteringByArchitectureGroup',
      'cancelTimeFlexibleAggregationRequests',
      'getRecalculatingSpecificPricingGroups',
    ]),
    ...mapHierarchyActions([
      'markUnitApprove',
      'updatePgApproval',
      'updateCategoryApproval',
      'updateHierarchy',
      'updateHierarchies',
      'fetchHierarchy',
    ]),
    ...mapActions('scenarioMetadata', [
      'getAggregatedScenarios',
      'fetchAllScenarioDescriptionsForWorkpackage',
    ]),
    ...mapActions('filters', ['resetState', 'setSelectedFilter']),

    setGridViewFilter(attributeFilters) {
      this.setSelectedFilter({
        filterName: 'retailAttributesFilter',
        filterValue: attributeFilters,
      });
      this.debounceUpdateGridView();
    },

    debounceUpdateGridView: debounce(async function() {
      this.updateGridView();
    }, clientConfig.inputDebounceValue),

    async updateGridView() {
      this.loadingHierarchy = true;
      await Promise.all([
        this.fetchItems({
          levelEntryKey: this.selectedLevelEntryKey,
          isUnitManagerView: this.isUnitManagerView,
        }),
        await this.getAlertCounts(),
      ]);
      // don't remove "to", it's required to prevent printing of "unhandled promise rejection" errors
      await to(
        Promise.all([
          this.fetchAggregations(),
          ...(!this.isUnitManagerView ? [this.getAggregatedScenarios()] : []),
        ]).then(this.globalEmit('hierarchy-refreshed'))
      );
      this.loadingHierarchy = false;
    },

    releasePricingGroup(shouldRelease = false) {
      if (shouldRelease) {
        if (this.categoryToRelease) {
          this.updateHierarchies({ updates: this.formatUpdates() });
        } else {
          this.updateHierarchy({
            levelEntryKey: this.pricingGroupToRelease.levelEntryKey,
            updates: { released: { isReleased: true, releaseDate: Date.now() } },
          });
        }
      }
      this.resetPricingGroupsToRelease();
      this.setCategoryToRelease({ category: null });
      this.pricingGroupToRelease = null;
      this.isReleaseConfirmDialogueOpen = false;
    },
    async releaseCategory(category) {
      const pricingGroups = this.getHierarchy(
        pg =>
          pg.parentId === category.levelEntryKey && get(pg, 'released.isReleased', false) === false
      );
      this.setPricingGroupsToRelease({ pricingGroups });
      this.isReleaseConfirmDialogueOpen = true;
    },
    releasePG() {
      this.pricingGroupToRelease = this.pricingGroupsToRelease[0];
      this.isReleaseConfirmDialogueOpen = true;
    },

    pricingSpecialistPricingGroupApproval({ levelEntryKey, approved }) {
      this.updateHierarchy({
        levelEntryKey,
        updates: {
          'approvalStatus.pricingSpecialistApproval': {
            approved,
            approvalDate: Date.now(),
            userId: this.profile.username,
          },
        },
      });
    },

    categoryManagerPricingGroupApproval({ levelEntryKey, approved }) {
      this.updatePgApproval({
        levelEntryKey,
        updates: {
          'approvalStatus.categoryManagerApproval': {
            approved,
            approvalDate: Date.now(),
            userId: this.profile.username,
          },
        },
      });
    },

    pricingSpecialistCategoryApproval({ levelEntryKey, approved }) {
      const pricingGroups = this.getHierarchy(pg => pg.parentId === levelEntryKey);
      this.updateCategoryApproval({
        updates: this.formatApprovalUpdates(pricingGroups, approved, 'pricingSpecialistApproval'),
      });
    },

    categoryManagerCategoryApproval({ levelEntryKey, approved }) {
      const pricingGroups = this.getHierarchy(pg => pg.parentId === levelEntryKey);
      this.updateCategoryApproval({
        updates: this.formatApprovalUpdates(pricingGroups, approved, 'categoryManagerApproval'),
      });
    },

    approveUnit({ levelEntryKey }) {
      const sapExportFields = this.getSapExportFields();
      this.markUnitApprove({
        levelEntryKey,
        updates: {
          'approvalStatus.unitManagerApproval': {
            approved: true,
            approvalDate: Date.now(),
            userId: this.profile.username,
          },
        },
        sapExportFields,
      });
    },

    formatUpdates() {
      const updates = this.pricingGroupsToRelease.map(pg => ({
        filter: {
          levelEntryKey: pg.levelEntryKey,
        },
        update: {
          released: {
            isReleased: true,
            releaseDate: Date.now(),
          },
        },
      }));
      return updates;
    },
    formatApprovalUpdates(pricingGroups, approved, approver) {
      const updates = pricingGroups.map(pg => ({
        filter: {
          levelEntryKey: pg.levelEntryKey,
        },
        update: {
          [`approvalStatus.${approver}`]: {
            approved,
            approvalDate: Date.now(),
            userId: this.profile.username,
          },
        },
      }));
      return updates;
    },

    async onToggleFilteringByArchitectureGroup({
      isFilterEnabled,
      architectureGroupAttribute,
      architectureGroupId,
    }) {
      const attributeFiltersToKeep = this.retailAttributesFilter.filter(
        attr => attr.attributeKey === this.toolStoreGroupGlobalAttributeKey
      );
      await this.toggleFilteringByArchitectureGroup({
        isFilterEnabled,
        architectureGroupAttribute,
        architectureGroupId,
        attributeFiltersToKeep,
      });
      this.debounceUpdateGridView();
    },

    async fetchFreshGridviewAggregations() {
      await this.fetchGroupedAggregatedHierarchyLevelItems({
        refreshCache: true,
        loadingMsg: 'caching.gridview.loadingMessage',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.notification-text {
  color: $pricing-grey-dark;
  font-size: $error-text-font-size;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
@import '@style/base/_variables.scss';

.hierarchy {
  &__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }

  &__row {
    background-color: $pricing-white;

    &--group-headers,
    &--headers {
      th {
        background: $pricing-background;
        position: sticky;
        z-index: 6;
        text-align: left;
        padding: 0 1rem;
      }
    }

    &--group-headers th {
      top: 11.1rem;
    }

    &--headers th {
      top: 15.7rem;
    }

    td {
      padding: 0.4rem 0.7rem;
      vertical-align: middle;
    }
  }
}
</style>
